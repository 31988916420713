import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import cssOverrides from '../utils/cssOverrides';

export default function ActionButton({
  children,
  onClick,
  ariaLabel,
  className
}) {
  const overrides =
    useSelector(state => state.config.theme.cssOverrides?.CloseButton) || {};

  return (
    <Button
      css={overrides}
      onClick={onClick}
      aria-label={ariaLabel}
      className={className}
    >
      {children}
    </Button>
  );
}

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string
};

const Button = styled.button`
  background: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  font-weight: bold;
  transition: background-color 75ms;
  padding: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${cssOverrides}
`;
