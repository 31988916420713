import React from 'react';
import PropTypes, { string, object } from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useSelector } from 'react-redux';

import cssOverrides from '../../utils/cssOverrides';
import { decodeSpecialChars } from '../../utils/decodeSpecialChars';
import Linkify from 'react-linkify';
import { ReactComponent as File } from '../../assets/file.svg';
import { ReactComponent as FileDownload } from '../../assets/file-download.svg';
export default function UserMessage(props) {
  const theme = useSelector(state => state.config.theme);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const overrides = theme.cssOverrides?.UserMessage || {};
  switch (props.type) {
    case 'file':
      return (
        <SelfBubbleFile theme={theme} css={overrides} animate={props.animate}>
          <div className="container">
            <div>
              <File />
            </div>
            <div className="name">{decodeSpecialChars(props.value.name)}</div>
            <div className="divider" />
            <a
              href={props.value.url}
              rel="noopener noreferrer"
              download
              target="_blank"
            >
              <FileDownload />
            </a>
          </div>
        </SelfBubbleFile>
      );
    case 'text':
    default:
      return (
        <SelfBubble
          theme={theme}
          css={overrides}
          animate={props.animate}
          fullScreen={fullScreen}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {decodeSpecialChars(props.title ? props.title : props.value)}
          </Linkify>
        </SelfBubble>
      );
  }
}

UserMessage.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.oneOfType([string, object]).isRequired,
  animate: PropTypes.bool.isRequired
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const SelfBubble = styled.p`
  background-color: ${props =>
    props.theme.userMessageColour?.background ||
    props.theme.primaryColour ||
    '#2574D4'};
  color: ${props => props.theme.userMessageColour?.text || 'white'};
  line-height: 1.3em;
  margin: 2px;
  max-width: 75%;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          @media screen and (min-width: 950px) {
            max-width: 65%;
          }
        `
      : null};
  width: fit-content;
  padding: 10px 14px;
  border-radius: 10px 6px 6px 10px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 100% 100%;
  transition: border-radius 300ms;
  word-break: break-word;
  white-space: pre-wrap;
  a {
    color: inherit;
  }
  ${cssOverrides}

  &:first-of-type {
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
  }
`;

const SelfBubbleFile = styled(SelfBubble)`
  & a {
    text-decoration: none;
  }

  .container {
    color: #06233d;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    gap: 7px;
    align-items: center;

    > * {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    > *:first-child {
      padding-left: 10px;
    }

    > *:last-child {
      padding-right: 10px;
    }
  }

  .divider {
    width: 0.5px;
    background-color: #06233d;
    align-self: stretch;
    opacity: 0.21;
    margin: 0px 7px;
  }

  .name {
    flex: 1;
  }
`;
