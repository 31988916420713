import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DEFAULT_PRIMARY_COLOR } from '../utils/constants';

export default function Avatar({
  primaryColour,
  image,
  initials,
  size = 'small'
}) {
  return (
    <AvatarWrapper className={size}>
      {image ? (
        <AvatarImage src={image} alt="Agent Avatar" />
      ) : (
        <Initials className={size} color={primaryColour}>
          {initials}
        </Initials>
      )}
    </AvatarWrapper>
  );
}

Avatar.propTypes = {
  image: PropTypes.string,
  initials: PropTypes.string,
  size: PropTypes.string,
  primaryColour: PropTypes.string
};

const AvatarWrapper = styled.div`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;

  &.small {
    height: 25px;
    width: 25px;
  }

  &.large {
    display: block;
    height: 52px;
    margin: 0 auto 5px;
    width: 52px;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`;

const Initials = styled.div`
  background-color: ${({ color }) => color || DEFAULT_PRIMARY_COLOR};
  color: #fff;
  font-weight: 500;
  height: 100%;
  line-height: 52px;
  margin: 0 auto 5px;
  text-align: center;
  width: 100%;

  &.small {
    font-size: 12px;
    line-height: 25px;
  }

  &.large {
    font-size: 22px;
    line-height: 52px;
  }
`;
