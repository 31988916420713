import Polyglot from 'node-polyglot';
import translations from './locales.json';

const DEFAULT_LOCALE = 'EN';

let locale =
  window.navigator.language.toLocaleUpperCase().split('-')[0] || DEFAULT_LOCALE;

if (!Object.keys(translations).includes(locale)) {
  locale = DEFAULT_LOCALE;
}

const polyglot = new Polyglot({ phrases: translations[locale] });

polyglot.locale(locale);

export default polyglot;
