/* eslint-disable complexity */
import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '../Avatar';
import { useTranslation } from '../../../localisation/TranslationContext';
import cssOverrides from '../../utils/cssOverrides';
import OtherMessage from './OtherMessage';
import { ReactComponent as Download } from '../../assets/download.svg';
import { completeChat } from '../../reducer';
import {
  DEFAULT_SECONDARY_COLOR,
  TRANSCRIPT_EXPIRY_HIDE_TIME,
  DEFAULT_PRIMARY_COLOR
} from '../../utils/constants';
import PropTypes from 'prop-types';

const messagePropType = PropTypes.shape({
  owner: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  replies: PropTypes.arrayOf(PropTypes.string.isRequired),
  top_element_style: PropTypes.string,
  date: PropTypes.string
});

export default function SystemMessage({
  message,
  isFirst,
  isLast,
  showReplies,
  animate
}) {
  const { t } = useTranslation();
  const theme = useSelector(state => state.config.theme);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const transcriptURL = message.value?.transcriptURL;
  const hasUserMessage = message.value?.hasUserMessage;
  const overrides = theme.cssOverrides?.SystemMessage || {};
  const dispatch = useDispatch();
  const [hideTransferChat, setHideTransferChat] = useState(false);
  const primaryColour = useSelector(
    state => state.config.theme.primaryColour || DEFAULT_PRIMARY_COLOR
  );

  const { agents } = useSelector(state => state.chat);
  const botAgent = agents.find(agent => agent.type === 'bot');
  const { displayName: agentDisplayName } = botAgent || {};

  const completeChatHandler = () => {
    setHideTransferChat(true);
    dispatch(completeChat());
  };

  const timeCompleted =
    message.type === 'complete-chat' && new Date(message.date).getTime();
  const timeoutRef = useRef();

  const [hideTranscriptDownload, setHideTranscriptDownload] = useState(
    timeCompleted &&
      TRANSCRIPT_EXPIRY_HIDE_TIME - (Date.now() - timeCompleted) <= 0
  );

  if (timeCompleted && !hideTranscriptDownload) {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(
        () => setHideTranscriptDownload(true),
        TRANSCRIPT_EXPIRY_HIDE_TIME - (Date.now() - timeCompleted)
      );
    }
  }

  useEffect(
    () => () => timeoutRef.current && clearTimeout(timeoutRef.current),
    []
  );

  if (message.type === 'joined-chat') {
    const avatar =
      agents.find(agent => agent._id === message.value._id)?.avatar ??
      message.value?.avatar;
    return (
      <JoinedMessage theme={theme} css={overrides}>
        <Avatar
          image={avatar}
          initials={message.value?.initials}
          size="large"
          primaryColour={primaryColour}
        />
        <b>{message.value.displayName}</b> {t('agent_joined_chat')}
      </JoinedMessage>
    );
  } else if (message.type === 'invitation') {
    return (
      <>
        <JoinedMessage theme={theme} css={overrides}>
          <b>{message.value.displayName}</b> {t('invited')}
        </JoinedMessage>
        {!hideTransferChat && message.value.type === 'team' && (
          <TransferChatButton onClick={completeChatHandler} css={overrides}>
            {t('transfer_me_back')} {agentDisplayName}
          </TransferChatButton>
        )}
      </>
    );
  } else if (message.type === 'left-chat') {
    return (
      <JoinedMessage theme={theme} css={overrides}>
        <b>{message.value.displayName}</b> {t('agent_left_chat')}
      </JoinedMessage>
    );
  } else if (message.type === 'complete-chat') {
    return (
      <ChatCompleteMessage theme={theme} css={overrides}>
        <ChatCompleteMessageBody>
          {message.value.reason === 'User ended chat'
            ? t('user_ended_chat')
            : t('chat_complete')}
        </ChatCompleteMessageBody>
        {transcriptURL && !hideTranscriptDownload && hasUserMessage && (
          <ChatCompleteMessageButtons>
            <Button href={transcriptURL} target="_blank" rel="noreferrer">
              {t('download_transcript')}
              <DownloadIcon />
            </Button>
          </ChatCompleteMessageButtons>
        )}
      </ChatCompleteMessage>
    );
  } else if (message.type === 'generic') {
    return (
      <OtherMessage
        type={message.type}
        value={message.value}
        owner={message.owner}
        isFirst={isFirst}
        isLast={isLast}
        replies={showReplies ? message.replies : undefined}
        top_element_style={message.top_element_style}
        animate={animate}
      />
    );
  } else {
    return (
      <SystemMessageWrapper
        animate={animate}
        theme={theme}
        css={overrides}
        fullScreen={fullScreen}
      >
        <SystemBubble>{message.value}</SystemBubble>
      </SystemMessageWrapper>
    );
  }
}

SystemMessage.propTypes = {
  message: messagePropType,
  showReplies: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  animate: PropTypes.bool
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const FirstLast = styled.div`
  border-radius: 2px;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0px;
  }
`;

const JoinedMessage = styled(FirstLast)`
  background: ${props =>
    props.theme.systemMessageColour || DEFAULT_SECONDARY_COLOR};
  border-color: ${props =>
    props.theme.systemMessageColour || DEFAULT_SECONDARY_COLOR};
  margin: 0px 24px 5px;
  padding: 8px 12px;
  word-break: break-word;
  ${cssOverrides}
`;

const ChatCompleteMessage = styled(FirstLast)`
  background: ${props =>
    props.theme.systemMessageColour || DEFAULT_SECONDARY_COLOR};
  border-color: ${props =>
    props.theme.systemMessageColour || DEFAULT_SECONDARY_COLOR};
  margin: 0px 24px 5px;

  &:last-child > :last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  ${cssOverrides}
`;

const ChatCompleteMessageBody = styled.div`
  padding: 8px 12px;
`;

const ChatCompleteMessageButtons = styled.div`
  background: white;
  padding: 8px 12px;
  border: 1px solid #eeeeee;
  border-top: none;
`;

const Button = styled.a`
  display: block;
  padding: 7px 11px;
  background-color: #eeeeee;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  color: black;
  text-decoration: none;

  &:hover,
  &:focus {
    border: 1px solid #aaaaaa;
  }

  &:focus {
    box-shadow: 0px 0px 8px 0px #000000 25%;
  }

  &:active {
    background-color: #dddddd;
  }
`;

const SystemMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.primaryColour || '#eeeeee'};
  line-height: 1.3em;
  margin: 2px;
  max-width: 75%;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          @media screen and (min-width: 950px) {
            max-width: 65%;
          }
        `
      : null};
  width: fit-content;
  padding: 10px 14px;
  border-radius: 10px 6px 6px 10px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 100% 100%;
  transition: border-radius 300ms;
  ${cssOverrides}

  &:first-of-type {
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
  }
`;

const SystemBubble = styled.div`
  opacity: 0.8;
  font-size: 0.9em;
  word-break: break-word;
`;

const DownloadIcon = styled(Download)`
  width: 14px;
  height: 14px;
  margin-left: 8px;
`;

const TransferChatButton = styled.button`
  border: thin dashed #d4d4d4;
  color: #4b4f56;
  padding: 7px 11px;
  background-color: transparent;
  border-radius: 10px;
  margin: 3px 24px 8px;
  padding: 8px 12px;
  font-size: 0.8em;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid #aaaaaa;
  }

  &:focus {
    box-shadow: 0px 0px 8px 0px #000000 25%;
  }

  &:active {
    background-color: #dddddd;
  }

  ${cssOverrides}
`;
