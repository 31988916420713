import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Arrow } from '../assets/speech-bubble-arrow.svg';

export default function Prompt({
  headerColour,
  position,
  isPrompting,
  cssOverrides,
  headerTextColour,
  prompt
}) {
  return (
    <>
      {!!prompt?.text && (
        <PromptWrapper
          headerColour={headerColour}
          alignment={position}
          duration={prompt.duration}
          isPrompting={isPrompting}
        >
          <PromptText
            duration={prompt.duration}
            cssOverrides={cssOverrides}
            promptStyle={prompt.style}
            headerColour={headerColour}
            headerTextColour={headerTextColour}
          >
            {prompt.text}
          </PromptText>
          {prompt.style === 'speech_bubble' && <Arrow />}
        </PromptWrapper>
      )}
    </>
  );
}

const getAlignment = alignment => {
  switch (alignment) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return alignment;
  }
};

const PromptWrapper = styled.div`
  ${({ headerColour, alignment, duration, isPrompting }) =>
    css`
      margin: 0px 16px;
      display: flex;
      flex-direction: column;
      align-items: ${getAlignment(alignment)};
      color: ${headerColour};
      position: absolute;
      bottom: calc(100% + 10px);

      opacity: ${isPrompting ? 1 : 0};
      transition: opacity 200ms linear;
      animation: ${duration}ms ${showPrompt};

      ${alignment === 'center'
        ? css`
            left: 0px;
            right: 0px;
          `
        : `${alignment}: 0;`}

      svg {
        margin: 0px 15px;
      }
    `}
`;

const showPrompt = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const PromptText = styled.div`
  ${({
    cssOverrides,
    promptStyle,
    headerColour,
    headerTextColour,
    alignment
  }) =>
    css`
      background-color: #ffffff;
      border-color: #ffffff;
      width: 150px;
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      line-height: 18.75px;
      transition: opacity;
      padding: 12px 15px;

      ${!!promptStyle &&
      css`
        color: ${headerTextColour};
        background-color: ${headerColour || '#2574D4'};
        text-align: left;
        font-size: 14px;
        font-weight: 700;
        width: max-content;
        min-width: 60px;
        max-width: 250px;
        padding: 14px 22px;

        border-radius: ${promptStyle === 'rectangular'
          ? `10px 10px ${alignment === 'left' ? '10px 0px' : '0px 10px'}`
          : '10px'};
      `}

      ${cssOverrides}
    `};
`;

Prompt.propTypes = {
  headerColour: PropTypes.string,
  headerTextColour: PropTypes.string,
  cssOverrides: PropTypes.string,
  isPrompting: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
  prompt: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    hideAfterPrompt: PropTypes.bool.isRequired,
    style: PropTypes.string
  })
};
