import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container as ChatContainer } from './chat';
import { Container as LauncherContainer } from './launcher';
import { closeChatPanel, openChatPanel } from './chat/reducer';
import { disableEmbed, enableEmbed } from './config';
import useDimensions from './useDimensions';

export default function App() {
  const dispatch = useDispatch();
  const launcherType = useSelector(state => state.config.theme.launcherType);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const isEmbedded = useSelector(state => !!state.config.embed.enabled);
  const embedTarget = useSelector(state => state.config.embed.target);
  const embedBreakpoint = useSelector(state => state.config.embed.breakpoint);
  const panelOpen = useSelector(state => state.chat.panelOpen);
  const userEngaged = useSelector(state => state.chat.userEngaged);
  const avatar = useSelector(
    state => state.chat.messages[0]?.messages[0]?.value?.avatar
  );
  const { width } = useDimensions();

  useEffect(() => {
    if (isEmbedded && width < embedBreakpoint) {
      if (!userEngaged && panelOpen) {
        dispatch(closeChatPanel());
      }
      dispatch(disableEmbed());
    } else if (!isEmbedded && width > embedBreakpoint) {
      dispatch(openChatPanel());
      dispatch(enableEmbed());
    }
  }, [width, isEmbedded, dispatch, embedBreakpoint, panelOpen, userEngaged]);

  useEffect(() => {
    if (fullScreen || isEmbedded) {
      dispatch(openChatPanel());
    }
  }, [dispatch, fullScreen, isEmbedded]);

  const firstPromptCompleted = useSelector(
    state => state.chat.launcher.firstPromptCompleted
  );
  const [isPrompting, setIsPrompting] = useState(
    fullScreen || isEmbedded ? false : !firstPromptCompleted
  );

  const setIsOpen = useCallback(() => dispatch(openChatPanel()), [dispatch]);

  return (
    <React.Fragment>
      {panelOpen && <ChatContainer setIsOpen={setIsOpen} />}
      {(!panelOpen || launcherType === 'floatingActionButton') && (
        <LauncherContainer
          setIsOpen={setIsOpen}
          isOpen={panelOpen}
          isPrompting={isPrompting}
          setIsPrompting={setIsPrompting}
          firstPromptCompleted={firstPromptCompleted}
          isEmbedded={isEmbedded}
          embedTarget={embedTarget}
          avatar={avatar}
        />
      )}
    </React.Fragment>
  );
}
