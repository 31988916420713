export default async function getConfig({ client, pipeline, apiURL }) {
  if (!pipeline) {
    throw new Error('Must include pipeline parameter.');
  }

  const response = await fetch(
    `${apiURL}/channels/clients/${client}/pipelines/${pipeline}/messenger/config`
  );

  if (!response.ok) {
    throw new Error(
      `No messenger config found for client ${client} and pipeline ${pipeline}`
    );
  }

  return response.json();
}
