import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import LinesEllipsis from 'react-lines-ellipsis';

import cssOverrides from '../../../utils/cssOverrides';
import { decodeSpecialChars } from '../../../utils/decodeSpecialChars';
import Button from '../shared/Button';
import elementPropType from './elementPropType';

export default function Element({ element, isMulti, minHeight, setMinHeight }) {
  const [isExpand, setExpand] = useState(false);
  const buttons = element.buttons || [];
  const theme = useSelector(state => state.config?.theme) || {};

  useEffect(() => {
    if (isMulti) {
      const subTitleMax = 110;
      if (element?.subtitle?.length >= subTitleMax) {
        setMinHeight('90');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMulti, element]);

  return (
    <ElementWrapper css={theme.cssOverrides?.GenericElement} isMulti={isMulti}>
      <ContentWrapper>
        {element.image_url && (
          <ImageComponent
            src={decodeSpecialChars(element.image_url)}
            isMulti={isMulti}
          />
        )}
        <OtherContent>
          <Title>{decodeSpecialChars(element.title)}</Title>
          {element.subtitle && (
            <Content
              css={theme.cssOverrides?.GenericElementContent}
              isMulti={isMulti}
              minHeight={minHeight}
              hasButtons={buttons.length > 0}
            >
              {isMulti ? (
                <ExpandableContent onClick={() => setExpand(!isExpand)}>
                  {isExpand ? (
                    decodeSpecialChars(element.subtitle)
                  ) : (
                    <LinesEllipsis
                      text={decodeSpecialChars(element.subtitle)}
                      maxLine="4"
                      ellipsis="..."
                      trimRight
                      basedOn="letters"
                    />
                  )}
                </ExpandableContent>
              ) : (
                decodeSpecialChars(element.subtitle)
              )}
            </Content>
          )}
          {buttons.length > 0 && (
            <Buttons>
              {buttons.map((button, i) => (
                <GenericButton
                  key={'carousel-button-' + i}
                  {...button}
                  css={theme.cssOverrides?.GenericElementButton}
                />
              ))}
            </Buttons>
          )}
        </OtherContent>
      </ContentWrapper>
    </ElementWrapper>
  );
}

const ImageComponent = ({ src, isMulti }) => {
  const [imageFit, setImageFit] = useState('auto');
  const imageAspectRatio = isMulti ? 1 : 300 / 130;

  useEffect(() => {
    if (!src) {
      return;
    }

    const img = document.createElement('img');
    img.src = src;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const newFit = aspectRatio <= imageAspectRatio ? 'cover' : 'contain';
      setImageFit(newFit);
    };
  }, [src, imageAspectRatio]);

  return <Image src={src} fit={imageFit} isMulti={isMulti} />;
};

ImageComponent.propTypes = {
  src: PropTypes.string,
  isMulti: PropTypes.bool
};

Element.propTypes = {
  element: elementPropType,
  isMulti: PropTypes.bool,
  minHeight: PropTypes.string,
  setMinHeight: PropTypes.func
};

const ElementWrapper = styled.div`
  border: 1px solid #ddd;
  border-radius: 10px;
  margin: 0;
  height: 100%;
  max-width: 300px;
  overflow: hidden;
  background-color: white;

  ${cssOverrides}
`;

const GenericButton = styled(Button)`
  ${cssOverrides}
`;

const Title = styled.div`
  color: #002036;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin: 18px 18px 5px;
  word-break: break-word;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const OtherContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ExpandableContent = styled.div`
  .LinesEllipsis-ellipsis {
    cursor: pointer;
  }
`;

const Content = styled.div`
  margin: ${({ hasButtons }) => (hasButtons ? '0 18px 0;' : '0 18px 18px;')}
  color: #002036;
  word-break: break-word;
  font-size: 16px;
  line-height: 20px;
  min-height: ${({ minHeight }) => `${minHeight}px`};

  ${cssOverrides}
`;

const Image = styled.img`
  width: 100%;
  min-height: 130px;
  object-fit: ${({ fit }) => fit ?? 'cover'};
  ${props =>
    props.isMulti
      ? css`
          height: 200px;
          max-height: 200px;
        `
      : null}

  ${cssOverrides}
`;

const Buttons = styled.div`
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  padding: 10px;
  margin-top: auto;
`;
