import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import ActionButton from './ActionButton';
import { setCanAnimate } from '../reducer';
import emitter from '../../emitter';
import customActions from './customActions';
import { DEFAULT_PRIMARY_COLOR } from '../utils/constants';

export default function ChatHeader({
  closeWindow,
  headerColour,
  headerTextColour
}) {
  const dispatch = useDispatch();
  const appName = useSelector(state => state.config.appName);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const isEmbedded = useSelector(state => !!state.config.embed.enabled);
  const appIconURL = useSelector(state => state.config.appIconURL);
  const cssOverrides = useSelector(
    state => state.config.theme.cssOverrides?.ChatHeader
  );
  const customAction = useSelector(state => state.config.customAction);
  const headerBackgroundColour = headerColour
    ? headerColour
    : DEFAULT_PRIMARY_COLOR;
  const headerTextColor = headerTextColour ? headerTextColour : 'white';

  return (
    <Header
      headerColour={headerBackgroundColour}
      headerTextColour={headerTextColor}
      cssOverrides={cssOverrides}
    >
      {appIconURL && (
        <ImageWrapper>
          <Image src={appIconURL} />
        </ImageWrapper>
      )}
      <HeaderText>{appName}</HeaderText>

      <Actions>
        {customAction && (
          <CustomAction
            onClick={customAction.onClick}
            ariaLabel={customAction.action}
          >
            {customActions[customAction.action]({
              color: headerTextColor === 'white' ? 'white' : '#009081'
            })}
          </CustomAction>
        )}
        {!fullScreen && !isEmbedded && (
          <ActionButton
            onClick={() => {
              closeWindow();
              emitter.emit('window-closed');
              dispatch(setCanAnimate(true));
            }}
            ariaLabel={'Close chat'}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="times"
              role="img"
              viewBox="0 0 352 512"
              style={{ height: '100%', width: '100%' }}
            >
              <path
                fill={headerTextColor}
                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
              />
            </svg>
          </ActionButton>
        )}
      </Actions>
    </Header>
  );
}

ChatHeader.propTypes = {
  closeWindow: PropTypes.func.isRequired,
  headerColour: PropTypes.string,
  headerTextColour: PropTypes.string
};

const ImageWrapper = styled.div`
  background-color: white;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 15px;
  height: 15px;
  object-fit: fill;
`;

const Header = styled.div`
  display: flex;
  background-color: ${({ headerColour }) => headerColour};
  color: ${({ headerTextColour }) => headerTextColour};
  padding: 6px 10px;
  align-items: center;
  ${({ cssOverrides }) =>
    css`
      ${cssOverrides}
    `}
`;

const HeaderText = styled.div`
  font-weight: 500;
  font-size: 1em;
  margin-left: 10px;
`;

const Actions = styled.div`
  margin-left: auto;
  min-height: 30px;
`;

const CustomAction = styled(ActionButton)`
  margin-right: 8px;

  &:hover {
    background-color: unset;
  }
`;
