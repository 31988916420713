import {
  fetchFromSessionStorage,
  saveToSessionStorage
} from '../utils/sessionStorage';

export async function getAnonymousID() {
  const anonymousID = fetchFromSessionStorage('anonymousID');
  return anonymousID || fetchAndSaveAnonymousIDtoStorage();
}

export async function fetchAnonymousID() {
  const response = await fetch(`${process.env.API_URL}/auth/anonymousID`, {
    headers: {
      Accept: 'application/json'
    },
    method: 'GET'
  });

  return response.json();
}

export async function fetchAndSaveAnonymousIDtoStorage() {
  const fetchAnonymousIDResponse = await fetchAnonymousID();
  saveToSessionStorage('anonymousID', fetchAnonymousIDResponse.anonymousID);
  return fetchAnonymousIDResponse.anonymousID;
}
