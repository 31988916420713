import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useResizeObserver from 'use-resize-observer';

import { useSwipeable } from 'react-swipeable';

import ArrowButton from './ArrowButton';

const useCarouselHelpers = (elementCount, disableCarousel) => {
  const { ref, width } = useResizeObserver();
  const [dragData, setDragData] = useState({ lastTouch: 0, offset: 0 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [moves, setMoves] = useState([]);
  const [firstChild, setFirstChild] = useState();
  const CAROUSEL_ITEM_MARGIN = 10;
  const [CAROUSEL_ITEM_WIDTH, setItemWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setFirstChild(ref.current.children[0]);
    }
  }, [ref]);

  useEffect(() => {
    if (firstChild) {
      setItemWidth(firstChild.clientWidth);
    }
  }, [firstChild]);

  const resetTransition = useCallback(
    enable => {
      const transition = enable ? 'transform 0.35s ease' : 'none';
      for (const child of ref.current.children) {
        child.style['transition'] = transition;
      }
    },
    [ref]
  );

  const scrollNext = useCallback(
    index => {
      let tomove = dragData.offset - CAROUSEL_ITEM_WIDTH - CAROUSEL_ITEM_MARGIN;
      resetTransition(true);
      setDragData({ offset: tomove, lastTouch: 0 });
      setCurrentIndex(index);
    },
    [resetTransition, dragData, CAROUSEL_ITEM_WIDTH]
  );

  const scrollPrev = useCallback(
    index => {
      const tomove = dragData.offset + moves[index - 1];
      resetTransition(true);
      setDragData({ offset: Number.isNaN(tomove) ? 0 : tomove, lastTouch: 0 });
      setCurrentIndex(index);
    },
    [moves, resetTransition, dragData]
  );

  const onClickNext = () => scrollNext(currentIndex + 1);
  const onClickPrev = () => scrollPrev(currentIndex - 1);

  useEffect(() => {
    async function populateMoves() {
      if (!disableCarousel && width) {
        const itemWidth = CAROUSEL_ITEM_WIDTH + CAROUSEL_ITEM_MARGIN;
        const visibleItems = width / itemWidth;
        const remaining = elementCount - visibleItems;
        const listOfMoves = await calculateMoves(itemWidth, remaining);
        setMoves(listOfMoves);
      }
    }

    populateMoves();
  }, [width, disableCarousel, elementCount, CAROUSEL_ITEM_WIDTH]);

  const calculateMoves = (itemWidth, remaining) => {
    remaining = Math.round(remaining * 10) / 10;
    let moveByList = [];
    while (remaining > 0) {
      if (remaining > 1) {
        moveByList.push(itemWidth);
        remaining = remaining - 1;
      } else {
        moveByList.push(itemWidth * remaining);
        remaining = remaining - remaining;
      }
    }
    return moveByList;
  };

  return {
    ref,
    currentIndex,
    currentOffset: dragData.offset,
    onClickNext,
    onClickPrev,
    moves
  };
};
export default function Carousel({ children }) {
  const [disableCarousel, setDisableCarousel] = useState(false);
  const {
    ref: carouselRef,
    currentIndex,
    currentOffset,
    onClickNext,
    onClickPrev,
    moves
  } = useCarouselHelpers(children.length, disableCarousel);
  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      if (currentIndex !== moves.length) {
        onClickNext();
      }
    },
    onSwipedRight: () => {
      if (currentIndex !== 0) {
        onClickPrev();
      }
    }
  });

  useEffect(() => {
    function handleResize() {
      const ITEM_WIDTH = carouselRef.current.children[0].clientWidth;
      const wrapWidth = carouselRef.current.offsetWidth - 45;
      const childrenWidth =
        children.length * ITEM_WIDTH + (children.length - 1) * 10;
      setDisableCarousel(childrenWidth < wrapWidth ? true : false);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    const ITEM_WIDTH = carouselRef.current.children[0].clientWidth;
    const wrapWidth = carouselRef.current.offsetWidth - 45;
    const childrenWidth =
      children.length * ITEM_WIDTH + (children.length - 1) * 10;
    setDisableCarousel(childrenWidth < wrapWidth ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Swipable {...(!disableCarousel ? { ...handlers } : {})}>
      <CarouselWrapper
        ref={carouselRef}
        currentOffset={currentOffset}
        disableCarousel={disableCarousel}
      >
        {children}
      </CarouselWrapper>
      <ArrowButton
        direction="prev"
        currentIndex={currentIndex}
        onClick={onClickPrev}
        disableCarousel={disableCarousel}
        moves={moves.length}
      />
      <ArrowButton
        direction="next"
        currentIndex={currentIndex}
        onClick={onClickNext}
        disableCarousel={disableCarousel}
        moves={moves.length}
      />
    </Swipable>
  );
}
Carousel.propTypes = {
  children: PropTypes.node
};

const Swipable = styled.div`
  display: flex;
`;

const CarouselWrapper = styled.div`
  display: flex;
  width: calc(100% + 45px);
  overflow-x: hidden;
  margin-left: -45px;
  padding-left: 45px;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    /* transition: transform 0.35s ease; */
    transform: ${({ currentOffset }) =>
      `translate3d(${currentOffset}px, 0, 0)`};
  }
`;
