export const persist = store => next => action => {
  const results = next(action);
  const state = store.getState();

  window.sessionStorage.setItem(
    `lobster/${state.config.client}/${state.config.pipeline}/state/${state.config.appName}`,
    JSON.stringify(state)
  );
  return results;
};

export const loadState = state => {
  try {
    const storedState = JSON.parse(
      window.sessionStorage.getItem(
        `lobster/${state.config.client}/${state.config.pipeline}/state/${state.config.appName}`
      )
    );

    return {
      ...storedState,
      ...state
    };
  } catch (error) {
    return state;
  }
};
