import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

const generateRandomHeight = (minTime, maxTime) => {
  return Math.floor(Math.random() * (maxTime - minTime + 1)) + minTime;
};

export default function TypingIndicator() {
  const theme = useSelector(state => state.config.theme);

  return (
    <IndicatorWrapper theme={theme}>
      <IndicatorBar theme={theme}></IndicatorBar>
      <IndicatorBar theme={theme}></IndicatorBar>
      <IndicatorBar theme={theme}></IndicatorBar>
      <IndicatorBar theme={theme}></IndicatorBar>
      <IndicatorBar theme={theme}></IndicatorBar>
    </IndicatorWrapper>
  );
}

const changeHeight = () => keyframes`
  from {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(0.5);
  }

  to {
    transform: scaleY(1);
  }
`;

const IndicatorWrapper = styled.div`
  background-color: ${props =>
    props.theme.botMessageColour?.background || '#EEEEEE'};
  font-size: 0;
  width: 60px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 10px;
`;

const IndicatorBar = styled.div`
  background-color: ${props => props.theme.botMessageColour?.text || '#888888'};
  width: 5px;
  min-height: 5px;
  height: 16px;
  border-radius: 15px;
  margin-right: 3px;
  animation: ${changeHeight} 1s linear infinite;
  animation-delay: ${() => generateRandomHeight(0, 500)}ms;
`;
