import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const defaultStyles = {
  marginRight: '15px'
};

export default function NavigateIcon({ style, navigateIconURL }) {
  if (navigateIconURL) {
    return <Image src={navigateIconURL} />;
  } else {
    const mergedStyles = { ...defaultStyles, ...style };
    return (
      <SvgBackground>
        <svg
          style={mergedStyles}
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM5.87723 14.25C5.83259 14.2701 5.77902 14.25 5.75893 14.2031C5.75 14.1808 5.75 14.154 5.75893 14.1317L7.96205 9.09822L10.9107 12.0469L5.87723 14.25ZM14.25 5.87723L12.0469 10.9107L9.09822 7.96205L14.1317 5.75893C14.1763 5.73884 14.2299 5.75893 14.25 5.8058C14.2589 5.82812 14.2589 5.85268 14.25 5.87723Z"
            fill="#092240"
          />
        </svg>
      </SvgBackground>
    );
  }
}

NavigateIcon.propTypes = {
  style: PropTypes.object.isRequired,
  navigateIconURL: PropTypes.string
};

const Image = styled.img`
  object-fit: cover;
  height: '25px';
`;

const SvgBackground = styled.div`
  width: 20px;
  height: 20px;
  background: #ffffff;
  border-radius: 20px;
`;
