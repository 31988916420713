import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { useTranslation } from '../../localisation/TranslationContext';
import PoweredBy from './PoweredBy';
import { SendIcon } from '../body/widgets/shared/Icons';
import {
  createSelfMessage,
  userStartedTyping,
  userStoppedTyping
} from '../reducer';

const DEFAULT_INPUT_HEIGHT = '2.5em';
const INPUT_SPACING = 1.5;
const MAX_VISIBLE_LINES = 5;

export default function InputBar({ style }) {
  const theme = useSelector(state => state.config.theme);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const isEmbedded = useSelector(state => !!state.config.embed.enabled);
  const cssOverrides = useSelector(
    state => state.config.theme?.cssOverrides?.InputBar
  );
  const sendIconURL = useSelector(state => state.config.sendIconURL);
  const dispatch = useDispatch();
  const [isFocused, setFocused] = useState(false);
  const [text, setText] = useState('');
  const [typing, setTyping] = useState(false);
  const [endChatOpen, setEndChatOpen] = useState(false);
  const { t } = useTranslation();
  const [inputHeight, setInputHeight] = useState(DEFAULT_INPUT_HEIGHT);
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [addressBar, setAddressBar] = useState(false);
  const hideTagline = useSelector(state => state.config.hideTagline);
  const sendButtonColour = /\S/.test(text)
    ? theme?.topBarColour?.background || theme?.primaryColour
    : '#EEEEEE';

  const inputStyles = {
    ...styles.input,
    ...(isFocused
      ? {
          borderColor: theme?.primaryColour
        }
      : null),
    height: inputHeight
  };

  if (/iPhone OS 15_[0-9]/.test(navigator.userAgent)) {
    visualViewport.onresize = () => {
      const displacementRatio =
        visualViewport.offsetTop / visualViewport.height;
      setAddressBar(displacementRatio > 0.1 && displacementRatio < 0.7);
    };
  }

  useEffect(() => {
    if (text === '') {
      setInputHeight(DEFAULT_INPUT_HEIGHT);
      setHasScrollbar(false);
    }
  }, [text]);

  function textChangeHandler(event) {
    const updateHeight = lineCount =>
      (event.target.style.height = `${lineCount + INPUT_SPACING}em`);
    let lineCount = 1;

    updateHeight(lineCount, INPUT_SPACING); // forces elements scrollHeight to be re-calculated

    const lineHeight = event.target.clientHeight / (lineCount + INPUT_SPACING); // value of 1 'em' in 'px'

    if (
      event.target.scrollHeight >
      (MAX_VISIBLE_LINES + INPUT_SPACING) * lineHeight
    ) {
      lineCount = MAX_VISIBLE_LINES;
      setHasScrollbar(true);
    } else {
      lineCount = Math.round(
        event.target.scrollHeight / lineHeight - INPUT_SPACING
      );
      setHasScrollbar(false);
    }

    updateHeight(lineCount); // we need to reverse the initial height of 1 line
    setInputHeight(`${lineCount + INPUT_SPACING}em`); // prevent inputHeight becoming stale
    setText(event.target.value);
  }

  function onKeyPress(event) {
    if (!typing && text !== '') {
      dispatch(userStartedTyping());
      setTyping(true);
    }

    keyPressChecks(event);
  }

  function keyPressChecks(event) {
    let val,
      end = event.target.selectionStart;

    if (
      ((event.key === 'Enter' && !event.shiftKey) ||
        (event.key === 'Enter' && event.shiftKey)) &&
      text === ''
    ) {
      event.preventDefault();
    }

    if (event.key === 'Enter' && !event.shiftKey && text !== '') {
      event.preventDefault();
      submit();
    }

    if (event.key === 'Enter' && event.shiftKey && text !== '') {
      event.preventDefault();
      val = text.substring(0, end) + '\n' + text.substring(end, text.length);
      setText(val);
      event.stopPropagation();
    }
  }

  function submit() {
    if (/\S/.test(text)) {
      dispatch(createSelfMessage(text));
      setText('');
      setInputHeight(DEFAULT_INPUT_HEIGHT);
    }
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setTyping(false);
      dispatch(userStoppedTyping());
    }, 3000);

    return () => {
      clearTimeout(handler);
    };
  }, [text, dispatch]);

  useEffect(() => {
    if (endChatOpen) {
      setEndChatOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused, setEndChatOpen]);

  return (
    <Footer
      fullScreen={fullScreen}
      isEmbedded={isEmbedded}
      isFocused={isFocused}
      addressBar={addressBar}
      cssOverrides={style}
    >
      <div style={styles.mainFooter}>
        <div style={styles.inputWrapper}>
          <Input
            cssOverrides={cssOverrides}
            style={inputStyles}
            onFocus={useCallback(() => setFocused(true), [setFocused])}
            onBlur={useCallback(() => setFocused(false), [setFocused])}
            onChange={useCallback(textChangeHandler, [])}
            onKeyPress={onKeyPress}
            value={text}
            type="text"
            maxLength="512"
            placeholder={t('input_placeholder')}
            autoFocus={theme?.placement !== 'embedded' && !isEmbedded}
            hasScrollbar={hasScrollbar}
          />
        </div>
        <SubmitButton
          onClick={submit}
          aria-label={'Send message'}
          tagline={hideTagline}
        >
          <SendIcon
            sendIconURL={sendIconURL}
            style={{
              ...styles.sendIcon,
              color: sendButtonColour
            }}
          />
        </SubmitButton>
        {!hideTagline && <PoweredBy />}
      </div>
    </Footer>
  );
}

InputBar.propTypes = {
  style: PropTypes.object
};

const styles = {
  mainFooter: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    margin: '12px',
    marginBottom: '10px',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  subFooter: {
    opacity: 0.5,
    textAlign: 'center',
    fontSize: '0.9em',
    padding: '6px'
  },
  inputSeparator: {
    position: 'absolute',
    width: 0,
    top: '-1px',
    left: '50%',
    height: '2px',
    transition: 'width 250ms, left 250ms'
  },
  inputSeparatorHighlighted: {
    width: '100%',
    left: 0
  },
  inputWrapper: {
    flex: 1
  },
  input: {
    width: '100%',
    padding: '10px 50px 10px 20px',
    fontSize: '1em',
    border: '1px solid',
    borderColor: '#CCCCCC',
    borderRadius: '20px',
    transition: 'border-color .1s, box-shadow .1s'
  },
  sendIcon: {
    height: '25px'
  },
  navigateIcon: {
    width: '20px',
    height: '20px'
  },
  closeTooltip: {
    height: '14px',
    width: '16px',
    marginRight: '7px'
  }
};

const Footer = styled.div`
  border: 1px solid #cccccc;
  border-radius: 0 0 6px 6px;

  ${({ fullScreen, isEmbedded }) =>
    fullScreen ||
    (isEmbedded &&
      css`
        border: none;
        border-top: 1px solid #cccccc;
        border-radius: 0;
      `)}

  ${({ cssOverrides }) =>
    css`
      ${cssOverrides}
    `}

  ${({ isFocused, addressBar }) =>
    isFocused &&
    addressBar &&
    css`
      margin-bottom: 50px;
    `}
`;

const SubmitButton = styled.button`
  margin: 0 0 0 10px;
  background: none;
  border: none;
  position: absolute;
  right: 0px;
  ${({ tagline }) => (!tagline ? 'margin-bottom: 20px' : '')};
`;

const Input = styled.textarea`
  width: 100%;
  max-height: 95px;
  padding: 10px 15px;
  font-size: 1em;
  border: 1px solid;
  border-color: #f5f5f5;
  border-radius: 20px;
  transition: border-color 0.1s, box-shadow 0.1s;
  overflow: hidden;
  resize: none;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  -webkit-appearance: none;
  touch-action: manipulation;
  outline: none;

  ${({ hasScrollbar }) =>
    hasScrollbar &&
    css`
      overflow-y: scroll;
    `}

  ${({ cssOverrides }) =>
    css`
      ${cssOverrides}
    `}
`;
