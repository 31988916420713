import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../shared/Button';
import { useSelector } from 'react-redux';

export default function ButtonWidget({ text, buttons }) {
  const theme = useSelector(state => state.config.theme);
  const allButtonsEmpty = buttons.filter(
    button => button.title !== '' || button.url !== ''
  );

  return (
    <ButtonWrapper>
      <Bubble theme={theme}>
        <BubbleText theme={theme}>{text}</BubbleText>
      </Bubble>
      <Buttons isEmpty={!allButtonsEmpty.length}>
        {buttons.map((button, i) => (
          <Button key={'button-widget-button-' + i} {...button} />
        ))}
      </Buttons>
    </ButtonWrapper>
  );
}

ButtonWidget.propTypes = {
  text: PropTypes.string.isRequired,
  buttons: PropTypes.array.isRequired
};

const ButtonWrapper = styled.div`
  margin: 2px;
  max-width: 380px;
`;

const Bubble = styled.div`
  background-color: ${props =>
    props.theme.botMessageColour?.background || '#EEEEEE'};
  border: 1px solid ${props => props.color || '#EEEEEE'};
  padding: 10px 14px;
  border-radius: 10px 10px 0 0;
`;

const BubbleText = styled.p`
  font-size: 1em;
  line-height: 1.3em;
  color: ${props => props.theme.botMessageColour?.text || '#4b4f56'};
  margin: 0;
  word-break: break-word;
`;

const Buttons = styled.div`
  border: 1px solid ${props => props.color || '#EEEEEE'};
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  padding: ${({ isEmpty }) => (isEmpty ? '0px' : '10px')};
`;
