const axios = require('axios');

export async function storeFile(file, uploadToken, clientId) {
  const response = await axios.post(
    `${process.env.STORAGE_URL}/storage/files?uploadToken=${uploadToken}`,
    file,
    {
      headers: {
        'Content-Type': file.type,
        'x-storage-type': 'short-term',
        'x-ebiai-account-id': clientId
      }
    }
  );

  const { url } = response.data;
  return url;
}
