import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { ReactComponent as ChevronRight } from '../assets/right-thin-chevron.svg';
import SelectScrollPane from './SelectScrollPane';
import { createSelfMessage } from '../reducer';

const SCROLL_BOX_HEIGHT = 200;
const QUICK_REPLY_MAX_LENGTH = 24;

export default function QuickRepliesSelect({ replies, animate }) {
  const replyRefs = useRef([]);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('Select a reply');
  const [showMenu, setShowMenu] = useState(false);
  const [toolTip, setTooltip] = useState({
    show: false,
    text: ''
  });

  useEffect(() => {
    if (!showMenu) {
      setTooltip({
        show: false,
        text: ''
      });
    }
  }, [showMenu]);

  useEffect(() => {
    replyRefs.current = replyRefs.current.slice(0, replies.length);
  }, [replies]);

  const showTooltip = reply => {
    if (showMenu) {
      setTooltip({
        show: true,
        text: reply
      });
    }
  };

  return (
    <QuickRepliesSelectWrapper animate={animate}>
      {toolTip.show && <ToolTip style={{ top: 0 }}>{toolTip.text}</ToolTip>}
      {showMenu && (
        <SelectDropDown
          onMouseLeave={() => {
            setShowMenu(false);
            setTooltip({ show: false, text: '' });
          }}
          onMouseEnter={() => setShowMenu(true)}
        >
          <SelectScrollPane>
            <Space height={'15px'} />
            {replies.map((reply, i) => (
              <QuickReplyOption
                key={'reply-option-' + i}
                id={'select-reply-option-' + i}
                ref={el => (replyRefs.current[i] = el)}
                onMouseEnter={() =>
                  reply.length > QUICK_REPLY_MAX_LENGTH
                    ? showTooltip(reply)
                    : setTooltip({ show: false, text: '' })
                }
                onClick={() => {
                  dispatch(createSelfMessage(reply, reply));
                  setSelectedOption(reply);
                  setShowMenu(false);
                }}
              >
                <OptionText truncate={reply.length > QUICK_REPLY_MAX_LENGTH}>
                  {reply}
                </OptionText>
              </QuickReplyOption>
            ))}
            <Space height={'15px'} />
          </SelectScrollPane>
        </SelectDropDown>
      )}
      <SelectButton
        open={showMenu}
        onClick={() => setShowMenu(!showMenu)}
        id="selectButton"
        truncate={selectedOption.length > QUICK_REPLY_MAX_LENGTH}
      >
        <span>{selectedOption}</span>
        <ArrowNext />
      </SelectButton>
    </QuickRepliesSelectWrapper>
  );
}

QuickRepliesSelect.propTypes = {
  replies: PropTypes.arrayOf(PropTypes.string).isRequired,
  animate: PropTypes.bool.isRequired
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const QuickRepliesSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 200px;
  max-width: 200px;
  margin: 4px 2px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 0 0;
`;

const SelectDropDown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: 0px 12px 24px -12px rgb(0 0 0 / 50%);
  position: absolute;
  bottom: 130%;
  border-radius: 10px;
  height: ${SCROLL_BOX_HEIGHT}px;
  width: 100%;
  background: white;
  padding: 0;
`;

const QuickReplyOption = styled.button`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: none;
  background: white;
  padding: 7px 15px;

  &:hover,
  &:active {
    background: #eeeeee;
  }
`;

const OptionText = styled.span`
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 14px;

  ${({ truncate }) =>
    truncate &&
    css`
      text-overflow: ellipsis;
    `};
`;

const Space = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
`;

const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 1em;
  line-height: 1.3em;
  color: #4b4f56;
  margin: 0;
  height: 40px;
  width: 100%;
  background: #eeeeee;
  border: 1px solid ${props => (props.open ? '#002140' : '#d7d7d7')};
  border-radius: 10px;
  cursor: pointer;
  padding: 0 15px;

  &:hover,
  &:active {
    border: 1px solid #002140;
  }

  ${({ truncate }) =>
    truncate &&
    css`
      span {
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }
    `};
`;

const ArrowNext = styled(ChevronRight)`
  width: 12px;
  height: 12px;
  fill: rgb(0 33 64);
  transform: rotate(90deg);
`;

const ToolTip = styled.div`
  position: absolute;
  width: 140%;
  line-height: 1.4;
  background: white;
  padding: 5px 10px 8px;
  border: 1px solid #d5d3d3;
  z-index: 2;
  word-break: break-word;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0px 12px 24px -12px rgb(0 0 0 / 50%);
`;
