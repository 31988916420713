import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListItem from './ListItem';
import elementPropType from './elementPropType';
import { useSelector } from 'react-redux';
import cssOverrides from '../../../utils/cssOverrides';
import Button from '../shared/Button';
import { decodeSpecialChars } from '../../../utils/decodeSpecialChars';

export default function ListWidget({ elements, top_element_style }) {
  const theme = useSelector(state => state.config?.theme) || {};
  const firstElement = elements[0];

  return (
    <ListWrapper css={theme.cssOverrides?.ListWidget}>
      {firstElement.image_url && top_element_style === 'large' ? (
        <React.Fragment>
          <ListHero>
            <Text>
              <Title>{decodeSpecialChars(firstElement.title)}</Title>
              {firstElement.subtitle && (
                <Subtitle>{decodeSpecialChars(firstElement.subtitle)}</Subtitle>
              )}
            </Text>
            {firstElement.image_url && <Image src={firstElement.image_url} />}
          </ListHero>
          {elements.slice(1).map((element, index) => (
            <ListItem key={`list-${index}`} element={element} />
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {elements.slice(1).map((element, index) => (
            <ListItem key={`list-${index}`} element={element} />
          ))}
        </React.Fragment>
      )}
      {firstElement.buttons && firstElement.buttons.length && (
        <Buttons>
          {firstElement.buttons.map((button, i) => (
            <Button key={'list-button-' + i} {...button} />
          ))}
        </Buttons>
      )}
    </ListWrapper>
  );
}

ListWidget.propTypes = {
  elements: PropTypes.arrayOf(elementPropType),
  top_element_style: PropTypes.string.isRequired
};

const ListWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid;
  border-color: #ddd;
  margin: 0 30px 0 0;
  max-width: 380px;
  overflow: hidden;
  ${cssOverrides}
`;

const ListHero = styled.div`
  position: relative;
`;

const Text = styled.div`
  bottom: 20px;
  left: 15px;
  position: absolute;
  right: 15px;
  z-index: 1;
`;

const Title = styled.h3`
  color: #fff;
  margin: 0;
  word-break: break-word;
`;

const Subtitle = styled.div`
  color: #ccc;
  margin: 0;
  word-break: break-word;
`;

const Image = styled.img`
  display: block;
  filter: brightness(70%);
  height: 130px;
  object-fit: cover;
  width: 100%;
`;

const Buttons = styled.div`
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  padding: 10px;
`;
