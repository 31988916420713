import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';
import { useDispatch } from 'react-redux';

import QuickReply from './QuickReply';

import { createSelfMessage } from '../reducer';

const EMOJI_PATTERN = /^\p{Emoji}$/u;

function getDisplayMode(replies = []) {
  // quick replies have a string length of 2
  if (
    replies.length <= 5 &&
    replies.every(r => EMOJI_PATTERN.test(r) || r.length === 1)
  ) {
    return 'quick-reply';
  }
  if (replies.length > 2) {
    return 'column';
  }
  return replies.length < 2 ? 'single' : 'choice';
}

export default function QuickReplies({ replies, animate }) {
  const dispatch = useDispatch();
  const mode = getDisplayMode(replies);

  return (
    <QuickRepliesWrapper animate={animate} mode={mode}>
      {replies.map((reply, i) => (
        <QuickReply
          columnMode={replies.length > 2}
          key={'reply-' + i}
          title={reply}
          onClick={() => dispatch(createSelfMessage(reply, reply))}
        />
      ))}
    </QuickRepliesWrapper>
  );
}

QuickReplies.propTypes = {
  replies: PropTypes.arrayOf(PropTypes.string).isRequired,
  animate: PropTypes.bool.isRequired
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const QuickRepliesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 0 0;
  max-width: 380px;

  ${({ mode }) =>
    mode === 'quick-reply' &&
    css`
      flex-direction: row;
      width: 100%;
      & > button {
        width: 35px;
        min-width: 35px;
        padding: 5px;
      }
    `}
  ${({ mode }) =>
    mode === 'column' &&
    css`
      flex-direction: column;
      width: 100%;
    `}
  ${({ mode }) =>
    mode === 'choice' &&
    css`
      flex-direction: row;
    `}
  ${({ mode }) =>
    mode === 'single' &&
    css`
      flex-direction: row;
      width: 80%;
    `}
`;
