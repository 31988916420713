import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as ChevronLeft } from '../../../assets/chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../assets/chevron-right.svg';

import { MOBILE_WIDTH } from '../../../utils/constants';
export default function ArrowButton({
  direction,
  currentIndex,
  onClick,
  disableCarousel,
  moves
}) {
  const classNames = [];

  let disabled = false;
  if (direction === 'next') {
    classNames.push('arrow-next');
    if (currentIndex === moves || disableCarousel) {
      classNames.push('arrow-hidden');
      disabled = true;
    }
  } else {
    classNames.push('arrow-prev');
    if (currentIndex === 0 || disableCarousel) {
      classNames.push('arrow-hidden');
      disabled = true;
    }
  }

  return (
    <ArrowButtonWrapper
      type="button"
      aria-label={direction === 'next' ? 'Next item' : 'Previous item'}
      className={classNames.join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {direction === 'next' ? <ArrowNext /> : <ArrowPrev />}
    </ArrowButtonWrapper>
  );
}

ArrowButton.propTypes = {
  direction: PropTypes.oneOf(['next', 'prev']),
  moves: PropTypes.number,
  currentIndex: PropTypes.number,
  onClick: PropTypes.func,
  theme: PropTypes.any,
  disableCarousel: PropTypes.bool
};

const ArrowButtonWrapper = styled.button`
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 45%;
  transition: opacity 0.2s linear;
  font-family: primaryIcons;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #eeeeee;
  height: 30px;
  width: 30px;
  border-radius: 10px;
  transform: translate(0, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(51, 51, 51, 0.6);

  &:hover {
    border: 1px solid #aaaaaa;
    box-shadow: 0 0 12px rgba(51, 51, 51, 0.7);
  }

  &:focus {
    border: 1px solid #333333;
    outline: none;
  }

  &:active {
    border: 1px solid #aaaaaa;
    background: linear-gradient(0deg, #eeeeee, #eeeeee);
    outline: none;
    box-shadow: 0 0 12px rgba(51, 51, 51, 0.7);
  }

  &.arrow-prev {
    left: -35px;
  }

  &.arrow-next {
    right: 10px;
  }

  &.arrow-hidden {
    visibility: hidden;
  }

  > svg {
    width: 14px;
    height: 14px;
  }

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`;

const ArrowPrev = styled(ChevronLeft)`
  width: 14px;
  height: 14px;
`;

const ArrowNext = styled(ChevronRight)`
  width: 14px;
  height: 14px;
`;
