import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import useResizeObserver from 'use-resize-observer';
import emitter from '../emitter';
import throttle from './utils/throttle';
import {
  DEFAULT_PRIMARY_COLOR,
  LINK_COLOR,
  MOBILE_WIDTH
} from './utils/constants';
import cssOverrides from './utils/cssOverrides';
import debounce from './utils/debounce';
import {
  closeChatPanel,
  openChatPanel,
  createSelfMessage,
  checkStatus,
  setUserEngaged
} from './reducer';
import { ChatHeader } from './header';
import { Avatar, Message, TypingIndicator } from './body';
import ChatErrorBoundary from './body/ChatErrorBoundary';
import { InputBar } from './footer';

const DELAY_DURATION = 300;
const DEBOUNCE_DURATION = DELAY_DURATION;
const CLOSING_DURATION = 200;
const SYSTEM_PROMPTS = [
  'joined-chat',
  'left-chat',
  'complete-chat',
  'invitation'
];

export default function Container({ setIsOpen }) {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const fullScreen = useSelector(state => state.config.fullScreen);
  const isEmbedded = useSelector(state => !!state.config.embed.enabled);
  const startingMessage = useSelector(state => state.config.startingMessage);
  const chat = useSelector(state => state.chat);
  const scrollEnd = useRef();
  const scrollingContainerRef = useRef();
  const typingIndicatorRef = useRef(null);
  const theme = useSelector(state => state.config.theme);
  const position = theme.position || 'right';
  const { hideAvatar, hideHeader, placement } = theme;
  const overrides = useSelector(
    state => state.config.theme.cssOverrides?.ChatWindow
  );
  const typingIndicators = chat.typingIndicator;
  const primaryColour = theme.primaryColour || DEFAULT_PRIMARY_COLOR;
  const headerColour = theme.topBarColour?.background || primaryColour;
  const headerTextColour = theme.topBarColour?.text || 'white';
  const [overflow, setOverflow] = useState(window.innerHeight < 600);

  const scrollToChild = (element, child) => {
    if (!element || !child) {
      return;
    }

    element.scrollTo({
      top: child.offsetTop + child.offsetHeight,
      behavior: 'smooth'
    });
  };

  const onResize = useCallback(() => {
    debounce(
      () => scrollToChild(scrollingContainerRef.current, scrollEnd.current),
      DEBOUNCE_DURATION
    )();
  }, []);

  const handleWindowResize = () => {
    setOverflow(window.innerHeight < 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const throttledResizeCallback = throttle(onResize, 300);

  const { ref: resizeObserverRef } = useResizeObserver({
    onResize: throttledResizeCallback
  });

  const isNewChat = chat.messages.length > 0 ? false : true;

  const getAgent = agentId => {
    return chat.agents.filter(agent => agent._id === agentId)[0];
  };

  useEffect(() => {
    dispatch(openChatPanel(isNewChat));

    if (startingMessage && isNewChat) {
      setTimeout(() => {
        dispatch(createSelfMessage(startingMessage));
      }, 5000);
    }
  }, [dispatch, isNewChat, startingMessage]);

  useEffect(() => {
    const selfMessages = chat.messages.filter(
      message => message.owner === 'self'
    ).length;
    if (selfMessages === 1 && !chat.userEngaged) {
      emitter.emit('customer-engagement');
      dispatch(setUserEngaged(true));
    }
  }, [chat, dispatch]);

  useEffect(() => {
    dispatch(checkStatus());
  });

  return (
    <ChatWindow
      position={position}
      placement={placement}
      fullScreen={fullScreen}
      isEmbedded={isEmbedded}
      isClosing={isClosing}
      animate={chat.canAnimate}
      css={overrides}
      overflows={overflow}
    >
      {!(hideHeader && (fullScreen || isEmbedded)) && (
        <ChatHeader
          closeWindow={() => {
            setIsClosing(true);
            setTimeout(() => {
              setIsClosing(false);
              setIsOpen(false);
              dispatch(closeChatPanel());
            }, CLOSING_DURATION);
          }}
          headerColour={headerColour}
          headerTextColour={headerTextColour}
        />
      )}
      <ChatPanel fullScreen={fullScreen} ref={scrollingContainerRef}>
        <ChatPanelScroll aria-live="assertive">
          <ChatPanelScrollWrapper ref={resizeObserverRef}>
            {chat.messages.map((messageGroup, i) => (
              <MessageGroup
                messages={messageGroup.messages}
                owner={messageGroup.owner}
                agents={chat.agents}
                delay={messageGroup.owner === 'system' ? 0 : DELAY_DURATION}
                key={'message-group' + i}
              >
                {messageGroup.messages.map((message, j) => (
                  <ChatErrorBoundary key={'message-' + j}>
                    <Message
                      message={message}
                      isFirst={j === 0}
                      isLast={j + 1 === messageGroup.messages.length}
                      showReplies={
                        i + 1 === chat.messages.length &&
                        j + 1 === messageGroup.messages.length
                      }
                    />
                  </ChatErrorBoundary>
                ))}
              </MessageGroup>
            ))}
            {chat.messages[0]?.owner !== undefined &&
            typingIndicators.length > 0 ? (
              <TransitionGroup>
                {typingIndicators.map(userId => (
                  <CSSTransition
                    nodeRef={typingIndicatorRef}
                    key={'typing-indicator' + userId}
                    classNames="typingIndicator"
                    timeout={{ enter: DELAY_DURATION, exit: DELAY_DURATION }}
                  >
                    <TypingIndicatorGroup ref={typingIndicatorRef}>
                      {!hideAvatar && userId !== 'system' && (
                        <AvatarWrapper>
                          <Avatar
                            image={getAgent(userId)?.avatar}
                            initials={getAgent(userId)?.initials}
                            primaryColour={primaryColour}
                          />
                        </AvatarWrapper>
                      )}
                      <TypingIndicatorWrapper>
                        <TypingIndicator />
                      </TypingIndicatorWrapper>
                    </TypingIndicatorGroup>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            ) : (
              <TypingIndicatorEmpty />
            )}
            <div ref={scrollEnd} />
          </ChatPanelScrollWrapper>
        </ChatPanelScroll>
      </ChatPanel>
      <InputBar />
    </ChatWindow>
  );
}

Container.propTypes = {
  setIsOpen: PropTypes.func.isRequired
};

const slideUp = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    transform: translateY(calc(100% + 20px));
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(calc(100% + 20px));
  }
`;

const ChatWindow = styled.div`
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ isEmbedded }) =>
    !isEmbedded &&
    css`
      bottom: 20px;
    `}
  height: ${({ overflows }) => (overflows ? '93%' : '600px')};
  width: 375px;
  z-index: 2147483647;
  ${({ position, isEmbedded }) =>
    !isEmbedded &&
    css`
      ${position}: 20px
    `};
  position: ${({ isEmbedded }) => (isEmbedded ? 'relative' : 'fixed')};
  overflow: hidden;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  animation: ${({ isClosing }) =>
    isClosing
      ? css`
          ${slideDown} ${CLOSING_DURATION}ms
        `
      : css`
          ${slideUp} 300ms
        `};

  ${({ fullScreen, isEmbedded }) =>
    (fullScreen || isEmbedded) &&
    css`
      animation: none;
      border-radius: 0;
      box-shadow: none;
    `}

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      bottom: 0;
      height: 100vh;
      ${({ position }) =>
        css`
          ${position}: 0
        `};
      width: 100vw;
    `}

  ${({ isEmbedded }) =>
    isEmbedded &&
    css`
      height: 100%;
      width: 100%;
    `}    

  ${({ animate }) =>
    !animate &&
    css`
      animation: none;
    `}

  ${({ placement }) =>
    placement === 'embedded' &&
    css`
      position: relative;
      right: unset;
      bottom: unset;
      left: unset;
      top: unset;
      width: unset;
      height: 640px;
    `}

  @media screen and (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    bottom: 0;
    ${({ position }) =>
      css`
        ${position}: 0
      `};
  }

  a {
    color: ${LINK_COLOR};
  }
  ${cssOverrides}
`;

const ChatPanel = styled.div`
  height: 100%;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  overflow-y: scroll;

  ${({ fullScreen }) =>
    fullScreen &&
    css`
      border: none;
    `}
`;

const ChatPanelScroll = styled.div`
  min-height: 100%;
  overflow-y: hidden;
`;

const ChatPanelScrollWrapper = styled.div``;

function MessageGroup({ children, messages, owner, agents, delay = 0 }) {
  const hideAvatar = useSelector(state => state.config.theme.hideAvatar);
  const [delayState, setDelayState] = useState(false);
  const [isGeneric, setIsGeneric] = useState(false);
  const isEmpty = Boolean(
    children.filter(child => child?.props?.message?.value).length
  );
  const agentDetails = agents.find(agent => agent._id === owner);
  const primaryColour = useSelector(
    state => state.config.theme.primaryColour || DEFAULT_PRIMARY_COLOR
  );

  useEffect(() => {
    if (messages.filter(message => message.type === 'generic').length) {
      setIsGeneric(true);
    }
  }, [messages]);

  useEffect(() => {
    if (delay <= 0) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setDelayState(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  if (owner === 'self') {
    return (
      <MessageGroupWrapper isGeneric={isGeneric}>
        <SelfMessageGroup>{children}</SelfMessageGroup>
      </MessageGroupWrapper>
    );
  }

  if (owner === 'system' && SYSTEM_PROMPTS.includes(messages[0].type)) {
    return (
      <MessageGroupWrapper isGeneric={isGeneric}>
        <SystemMessageGroup>{children}</SystemMessageGroup>
      </MessageGroupWrapper>
    );
  }

  return isEmpty ? null : (
    <MessageGroupWrapper isGeneric={isGeneric}>
      {delayState && (
        <>
          {!hideAvatar && owner !== 'system' && (
            <AvatarWrapper>
              <Avatar
                image={agentDetails?.avatar}
                initials={agentDetails?.initials}
                primaryColour={primaryColour}
              />
            </AvatarWrapper>
          )}
          <OtherMessageGroup isGeneric={isGeneric}>
            {children}
          </OtherMessageGroup>
        </>
      )}
    </MessageGroupWrapper>
  );
}

MessageGroup.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.array.isRequired,
  owner: PropTypes.string.isRequired,
  agents: PropTypes.array.isRequired,
  delay: PropTypes.number
};

const AvatarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 7px 0;
  margin-right: 8px;
  transition: height 200ms;
  transition-delay: 300ms;
  animation: lobster-client--zoom-in 150ms;
`;

const TypingIndicatorWrapper = styled.div``;

const TypingIndicatorEmpty = styled.div`
  height: 39px;
  margin: 14px 10px;
`;

const TypingIndicatorGroup = styled.div`
  margin: 14px 10px;
  display: flex;
  flex-direction: row;
  transform-origin: 0 0;

  &.typingIndicator-enter {
    ${TypingIndicatorWrapper} {
      transform: scale(0);
    }
  }

  &.typingIndicator-enter-active {
    ${TypingIndicatorWrapper} {
      transform: scale(1);
      transition: all ${DELAY_DURATION}ms ease-in;
    }
  }

  &.typingIndicator-exit {
    ${TypingIndicatorWrapper} {
      transform: scale(1);
      transition: all ${DELAY_DURATION}ms ease-in;
    }
  }

  &.typingIndicator-exit-active {
    ${TypingIndicatorWrapper} {
      transform: scale(0);
      transition: all ${DELAY_DURATION}ms ease-in;
    }
  }
`;

const MessageGroupWrapper = styled.div`
  margin: ${({ isGeneric }) => (isGeneric ? '10px 0 10px 10px' : '10px')};
  display: flex;
  flex-direction: row;
  word-break: break-all;
`;

const OtherMessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isGeneric }) => (isGeneric ? 'calc(100% - 33px)' : '100%')};
`;

const SystemMessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: 0 10px;
`;

const SelfMessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`;
