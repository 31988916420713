import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Upload } from '../../../assets/upload.svg';
import { ReactComponent as Error } from '../../../assets/error.svg';
import { fileUpload } from '../../../reducer';

const DEFAULT_MAX_SIZE = 5;
export default function FileUploadWidget({ value }) {
  const [error, setError] = useState(value.error ?? '');
  const [file, setFile] = useState('');
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setError(value.error);
    if (value.error) {
      setFile('');
    }
  }, [value.error]);

  const fileTypes = ['jpeg', 'jpg', 'png', 'pdf', 'gif', 'txt'];

  const handleClick = () => {
    setError('');
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    const maxSize = value.maxMbSize ?? DEFAULT_MAX_SIZE;
    if (fileUploaded) {
      if (!fileTypes.includes(fileUploaded.name.split('.').pop())) {
        setError(
          `.${fileUploaded.name
            .split('.')
            .pop()} file extension is not supported`
        );
      } else if (fileUploaded.size > maxSize * 1024 * 1024) {
        setError('File exceeding maximum size');
      } else {
        setFile(fileUploaded.name);
        dispatch(
          fileUpload({
            file: fileUploaded,
            requestId: value.requestId,
            uploadToken: value.uploadToken
          })
        );
      }
    }
  };

  return (
    <FileUpload onClick={handleClick}>
      <div className="wrapper">
        <div className="container">
          <Upload />
          <div>
            <Title>Upload Document</Title>
            <SubTitle>5MB Maximum</SubTitle>
          </div>
        </div>

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
        {error && (
          <ErrorContainer>
            <Error /> <ErrorMessage>{error}</ErrorMessage>
          </ErrorContainer>
        )}
        {file && <ConfirmMessage>{file}</ConfirmMessage>}
      </div>
    </FileUpload>
  );
}

FileUploadWidget.propTypes = {
  value: PropTypes.shape({
    requestId: PropTypes.string.isRequired,
    uploadToken: PropTypes.string.isRequired,
    maxMbSize: PropTypes.number,
    error: PropTypes.string
  })
};

const FileUpload = styled.div`
  border-radius: 16px;
  margin: 5px 5px 5px 0px;
  word-break: break-word;
  cursor: pointer;

  background: #f6f6f6;
  border-radius: 16px;

  .wrapper {
    padding: 20px 30px;
    border-radius: 16px;

    background: linear-gradient(to right, #00203624 50%, transparent 0%) top
        repeat-x,
      linear-gradient(#00203624 50%, transparent 0%) right repeat-y,
      linear-gradient(to right, #00203624 50%, transparent 0%) bottom repeat-x,
      linear-gradient(#00203624 50%, transparent 0%) left repeat-y;
    background-size: 20px 1.5px, 1.5px 20px;
  }

  .container {
    justify-content: center;
    display: flex;
    gap: 10px;
  }
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 0px;
`;

const SubTitle = styled.p`
  font-size: 12px;
  margin: 0 0 5px 0;
  color: #899ea7;
`;

const ErrorMessage = styled(SubTitle)`
  text-align: center;
  font-size: 12px;
  margin: 0px;
  color: red;
`;

const ConfirmMessage = styled(SubTitle)`
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
  margin-top: 10px;
  color: green;
`;

const ErrorContainer = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
