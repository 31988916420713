import React from 'react';
import PropTypes from 'prop-types';
import SystemMessage from './messages/SystemMessage';
import SelfMessage from './messages/SelfMessage';
import OtherMessage from './messages/OtherMessage';

const messagePropType = PropTypes.shape({
  owner: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  replies: PropTypes.arrayOf(PropTypes.string.isRequired),
  top_element_style: PropTypes.string
});

const isAnimate = msg => {
  return Date.now() - Date.parse(msg.date) < 1000;
};

export default function Message({ message, isFirst, isLast, showReplies }) {
  switch (message.owner) {
    case 'system':
      return (
        <SystemMessage
          message={message}
          isFirst={isFirst}
          isLast={isLast}
          showReplies={showReplies}
          animate={isAnimate(message)}
        />
      );
    case 'self':
      return <SelfMessage {...message} animate={isAnimate(message)} />;
    default:
      return (
        <OtherMessage
          type={message.type}
          value={message.value}
          owner={message.owner}
          isFirst={isFirst}
          isLast={isLast}
          replies={showReplies ? message.replies : undefined}
          top_element_style={message.top_element_style}
          animate={isAnimate(message)}
        />
      );
  }
}

Message.propTypes = {
  message: messagePropType,
  showReplies: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool
};
