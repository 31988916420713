import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { setCanAnimate } from '../../chat/reducer';
import emitter from '../../emitter';
import Prompt from './Prompt';

const CLOSING_DURATION = 50;

const defaultStyles = {
  backgroundColor: '#2574D4'
};

export default function Window({
  setIsOpen,
  headerColour,
  headerTextColour,
  cssOverrides,
  position,
  avatarName,
  launcherIconURL,
  widgetType,
  isOpen,
  bottomMargin,
  sideMargin,
  isPrompting,
  promptCssOverrides,
  prompt,
  promptColour,
  promptTextColour
}) {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const withAvatar = widgetType === 'window_avatar_text';

  return (
    <Launcher
      open={isOpen}
      position={position}
      isClosing={isClosing}
      style={{
        ...defaultStyles,
        ...{
          backgroundColor: headerColour,
          color: headerTextColour
        }
      }}
      bottomMargin={bottomMargin}
      sideMargin={sideMargin}
    >
      <Prompt
        headerColour={promptColour}
        position={position}
        isPrompting={isPrompting}
        cssOverrides={promptCssOverrides}
        headerTextColour={promptTextColour}
        prompt={prompt}
      />
      <Button
        onClick={() => {
          dispatch(setCanAnimate(true));
          setIsClosing(true);
          setTimeout(() => {
            dispatch(setCanAnimate(false));
          }, 350);
          setTimeout(() => {
            setIsClosing(false);
            setIsOpen(true);
            emitter.emit('window-opened');
          }, CLOSING_DURATION);
        }}
        headerTextColour={headerTextColour}
        cssOverrides={cssOverrides}
      >
        {withAvatar && <AvatarImage src={launcherIconURL} />}
        {avatarName}
      </Button>
    </Launcher>
  );
}

const AvatarImage = styled.img`
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  height: 16px;
`;

Window.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  headerColour: PropTypes.string,
  cssOverrides: PropTypes.string,
  position: PropTypes.oneOf(['left', 'center', 'right']).isRequired,
  headerTextColour: PropTypes.string,
  avatarName: PropTypes.string,
  launcherIconURL: PropTypes.string,
  widgetType: PropTypes.oneOf(['window_text', 'window_avatar_text']),
  isOpen: PropTypes.bool,
  bottomMargin: PropTypes.number,
  sideMargin: PropTypes.number,
  isPrompting: PropTypes.bool,
  promptCssOverrides: PropTypes.string,
  prompt: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    hideAfterPrompt: PropTypes.bool.isRequired,
    style: PropTypes.string
  }),
  promptColour: PropTypes.string,
  promptTextColour: PropTypes.string
};

const Launcher = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  border-radius: 6px 6px 0 0;
  position: absolute;
  bottom: ${({ bottomMargin }) => bottomMargin}px;
  z-index: 2147483646;
  ${({ position, sideMargin }) =>
    position === 'center'
      ? css`
          left: 50%;
          transform: translateX(-50%);
        `
      : css`
          ${position}: ${sideMargin}px
        `};
  position: fixed;
  opacity: ${({ open }) => (open ? 0 : 1)};

  @media screen and (max-width: 480px) {
    width: calc(100vw - 64px);
  }
`;

const Button = styled.button`
  align-items: center;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 3;
  display: flex;
  text-align: unset;
  font: unset;
  border: none;
  color: ${({ headerTextColour }) =>
    headerTextColour ? headerTextColour : 'white'};
  box-shadow: rgba(0, 0, 0, 0.28) 0px 5px 15px;
  background: none;
  gap: 6px;
  font-size: 14px;
  font-weight: 700;
  padding: 15px;
  width: 375px;

  &:focus {
    outline: none;
    border: none;
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    padding: 6px 10px;
  }

  ${({ cssOverrides }) =>
    cssOverrides &&
    css`
      ${cssOverrides}
    `}
`;
