import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SCROLL_BOX_MIN_HEIGHT = 20;
const SCROLL_BOX_HEIGHT = 200;

export default function SelectScrollPane({
  children,
  className,
  ...restProps
}) {
  const [hovering, setHovering] = useState(false);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [isDragging, setDragging] = useState(false);

  const handleMouseOver = useCallback(() => {
    !hovering && setHovering(true);
  }, [hovering]);

  const handleMouseOut = useCallback(() => {
    !!hovering && setHovering(false);
  }, [hovering]);

  const handleDocumentMouseUp = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging]
  );

  const handleDocumentMouseMove = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();
        const scrollHostElement = scrollHostRef.current;
        const { scrollHeight, offsetHeight } = scrollHostElement;

        let deltaY = e.clientY - lastScrollThumbPosition;
        let percentage = deltaY * (scrollHeight / offsetHeight);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        );
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        );
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
  );

  const handleScrollThumbMouseDown = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      return;
    }
    const scrollHostElement = scrollHostRef.current;
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    let newTop =
      (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);
  }, [scrollBoxHeight]);

  const scrollHostRef = useRef();

  useEffect(() => {
    const scrollHostElement = scrollHostRef.current;
    const { clientHeight, scrollHeight } = scrollHostElement;
    const scrollThumbPercentage = clientHeight / scrollHeight;
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * clientHeight,
      SCROLL_BOX_MIN_HEIGHT
    );
    setScrollBoxHeight(scrollThumbHeight);
    scrollHostElement.addEventListener('scroll', handleScroll, true);
    return function cleanup() {
      scrollHostElement.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);

  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove);
    document.addEventListener('mouseup', handleDocumentMouseUp);
    document.addEventListener('mouseleave', handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener('mousemove', handleDocumentMouseMove);
      document.removeEventListener('mouseup', handleDocumentMouseUp);
      document.removeEventListener('mouseleave', handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  return (
    <ScrollHostContainer
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ScrollHost ref={scrollHostRef} className={className} {...restProps}>
        {children}
      </ScrollHost>
      <ScrollBar hovering={hovering}>
        <ScrollThumb
          hovering={hovering}
          style={{
            height: !isNaN(scrollBoxHeight)
              ? scrollBoxHeight - SCROLL_BOX_MIN_HEIGHT * 4
              : 4,
            top: scrollBoxTop
          }}
          onMouseDown={handleScrollThumbMouseDown}
        />
      </ScrollBar>
    </ScrollHostContainer>
  );
}

SelectScrollPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  className: PropTypes.string
};

const ScrollHost = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollHostContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const ScrollBar = styled.div`
  position: absolute;
  top: ${SCROLL_BOX_MIN_HEIGHT * 2}px;
  right: 15px;
  width: 2px;
  height: calc(${SCROLL_BOX_HEIGHT}px - ${SCROLL_BOX_MIN_HEIGHT * 4}px);
  border-radius: 7px;
  bottom: ${SCROLL_BOX_MIN_HEIGHT * 2}px;
  background-color: #d7d7d7;
`;

const ScrollThumb = styled.div`
  position: absolute;
  top: 0;
  width: 8px;
  height: ${SCROLL_BOX_MIN_HEIGHT}px;
  margin-left: -3px;
  border-radius: 7px;
  opacity: 1;
  background-color: #b9b9b9;
`;
