import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as Warning } from '../../../assets/warning.svg';

export default function ErrorWidget({ value }) {
  return (
    <ErrorWidgetWrapper>
      <Error>
        <WarningIcon />
        {value}
      </Error>
    </ErrorWidgetWrapper>
  );
}

const ErrorWidgetWrapper = styled.div`
  border: 1px solid ${props => props.color || '#EEEEEE'};
  border-radius: 10px;
  padding: 16px 14px;
  margin: 2px;
  max-width: 380px;
  overflow: hidden;
`;

const Error = styled.div`
  display: flex;
  align-items: center;
`;

const WarningIcon = styled(Warning)`
  margin-right: 8px;
  width: 16px;
  height: 15px;
`;

ErrorWidget.propTypes = {
  value: PropTypes.string.isRequired
};
