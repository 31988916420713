import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const defaultStyles = {
  marginLeft: '5px'
};

export default function InternalLink({ style, internalLinkURL }) {
  if (internalLinkURL) {
    return <Image src={internalLinkURL} />;
  } else {
    const mergedStyles = { ...defaultStyles, ...style };
    return (
      <svg
        style={mergedStyles}
        width="15px"
        height="15px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        id="internal-link-icon"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="icons8-external-link" fill="#000000" fillRule="nonzero">
            <path
              d="M5.51391748,13.0141165 C5.55085365,13.0129027 5.58769416,13.0096425 5.62426948,13.0043509 L11.9943865,13.0043509 C12.3550224,13.0094512 12.6904678,12.8199756 12.8722742,12.5084781 C13.0540806,12.1969805 13.0540806,11.8117213 12.8722742,11.5002238 C12.6904678,11.1887262 12.3550224,10.9992506 11.9943865,11.0043509 L7.90844898,11.0043509 L14.7014175,4.21138192 C14.9626583,3.96056535 15.0678931,3.58811493 14.9765363,3.23767287 C14.8851795,2.88723081 14.6115066,2.61355793 14.2610645,2.52220111 C13.9106225,2.43084428 13.538172,2.53607912 13.2873555,2.79731992 L6.49438648,9.59028842 L6.49438648,5.50435092 C6.49948677,5.143715 6.31001118,4.80826955 5.99851363,4.6264632 C5.68701607,4.44465684 5.30175688,4.44465684 4.99025933,4.6264632 C4.67876177,4.80826955 4.48928619,5.143715 4.49438648,5.50435092 L4.49438648,11.881304 C4.45533462,12.1720948 4.5459445,12.4653659 4.74221957,12.6834494 C4.93849464,12.9015328 5.22063395,13.0224276 5.51391748,13.0141165 Z M3.75,-0.000100431844 C1.6913439,-0.000100431844 0,1.691344 0,3.75 L0,13.75 C0,15.808656 1.6913439,17.5 3.75,17.5 L13.75,17.5 C15.808656,17.5 17.5001004,15.808656 17.5001004,13.75 L17.5001004,9 C17.5051003,8.63936408 17.3156247,8.30391864 17.0041272,8.12211228 C16.6926296,7.94030592 16.3073704,7.94030592 15.9958728,8.12211228 C15.6843753,8.30391864 15.4948997,8.63936408 15.5,9 L15.5,13.75 C15.5,14.723344 14.723344,15.5 13.75,15.5 L3.75,15.5 C2.776656,15.5 2,14.723344 2,13.75 L2,3.75 C2,2.776656 2.776656,2 3.75,2 L8.5,2 C8.86063592,2.00510029 9.19608136,1.8156247 9.37788772,1.50412715 C9.55969408,1.1926296 9.55969408,0.807370403 9.37788772,0.49587285 C9.19608136,0.184375296 8.86063592,-0.00510028955 8.5,-0.000100431844 L3.75,-0.000100431844 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    );
  }
}

InternalLink.propTypes = {
  style: PropTypes.object,
  internalLinkURL: PropTypes.string
};

const Image = styled.img`
  object-fit: cover;
  height: '15px';
`;
