import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ReactComponent as poweredByEbi } from '../assets/powered by EBI.svg';

const ebiUrl = clientId =>
  `https://ebi.ai/?utm_campaign=Self%20serve&utm_source=Messenger%20Tagline&utm_medium=${clientId}`;

export default function PoweredBy() {
  const clientId = useSelector(state => state.config.client);

  return (
    <Container>
      <Link href={ebiUrl(clientId)} target="_blank">
        <PoweredByIcon />
      </Link>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 14px;
  &:before(div) {
    margin-bottom: 20px;
    border: 1px solid red;
  }
`;

const PoweredByIcon = styled(poweredByEbi)`
  margin: 0 auto 5px auto;
  display: block;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 10px;
`;
