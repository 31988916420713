import React from 'react';
import styled from 'styled-components';
import cssOverrides from '../../../utils/cssOverrides';
import ListButton from './ListButton';
import listPropType from './elementPropType';
import { decodeSpecialChars } from '../../../utils/decodeSpecialChars';

export default function ListItem({ element }) {
  const buttons = element.buttons || [];
  return (
    <ElementWrapper>
      <Text>
        <Title>{decodeSpecialChars(element.title)}</Title>
        {element.subtitle && (
          <Subtitle>{decodeSpecialChars(element.subtitle)}</Subtitle>
        )}
        {buttons.map((button, i) => (
          <ListButton key={'list-item-button-' + i} {...button} />
        ))}
      </Text>
      {element.image_url && (
        <ImageMask>
          <Image src={element.image_url} />
        </ImageMask>
      )}
    </ElementWrapper>
  );
}

ListItem.propTypes = {
  element: listPropType
};

const ElementWrapper = styled.div`
  border-bottom: 1px solid;
  border-color: #eeeeee;
  display: flex;
  padding: 15px;
  ${cssOverrides}

  &:last-of-type {
    border: none;
  }
`;

const Text = styled.div`
  flex: 1;
`;

const Title = styled.h4`
  color: #4b4f56;
  font-weight: bold;
  margin: 0 0 2px;
  word-break: break-word;
`;

const Subtitle = styled.p`
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  word-break: break-word;
`;

const ImageMask = styled.div`
  border-radius: 5px;
  float: right;
  height: 75px;
  margin-left: 15px;
  overflow: hidden;
  width: 75px;
`;

const Image = styled.img`
  height: 100%;
  width: auto;
`;
