import PropTypes from 'prop-types';

const listPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image_url: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object)
});

export default listPropType;
