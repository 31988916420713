import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Carousel from './Carousel';
import Element from './Element';
import elementPropType from './elementPropType';
export default function GenericWidget({ elements, className }) {
  const [minHeight, setMinHeight] = useState('0');
  const isMulti = elements.length > 1;

  return (
    <GenericWrapper className={className} isMulti={isMulti}>
      {isMulti ? (
        <Carousel>
          {elements.map((element, index) => (
            <CarouselItem key={`carousel-${index}`}>
              <Element
                element={element}
                isMulti={isMulti}
                minHeight={minHeight}
                setMinHeight={setMinHeight}
              />
            </CarouselItem>
          ))}
        </Carousel>
      ) : (
        <Element element={elements[0]} isMulti={isMulti} />
      )}
    </GenericWrapper>
  );
}

GenericWidget.propTypes = {
  elements: PropTypes.arrayOf(elementPropType),
  className: PropTypes.string
};

const GenericWrapper = styled.section`
  margin: ${({ isMulti }) => (isMulti ? '7px -10px 7px 0' : '7px 2px 7px 2px')};
  position: relative;
  max-width: 100%;
`;

const CarouselItem = styled.div`
  scroll-snap-align: start;
  flex: 0 0 auto;
  width: calc(100% - 40px);
  max-width: 300px;

  & + & {
    margin-left: 10px;
  }
`;
