export const DISABLE_EMBED = 'lobster-client/config/DISABLE_EMBED';
export const ENABLE_EMBED = 'lobster-client/config/ENABLE_EMBED';

// eslint-disable-next-line default-param-last
export default (state = {}, action) => {
  switch (action.type) {
    case DISABLE_EMBED:
      return {
        ...state,
        embed: {
          ...state.embed,
          enabled: false
        }
      };
    case ENABLE_EMBED:
      return {
        ...state,
        embed: {
          ...state.embed,
          enabled: true
        }
      };
    default:
      return state;
  }
};

export const disableEmbed = () => ({
  type: DISABLE_EMBED
});

export const enableEmbed = () => ({
  type: ENABLE_EMBED
});
