import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CircularBubble } from '../assets/circular-bubble.svg';
import { ReactComponent as SpeechBubble } from '../assets/speech-bubble.svg';
import { DEFAULT_PRIMARY_COLOR } from '../../chat/utils/constants';

const AVATAR_DIMS = isMobile ? 54 : 70;

const Widget = memo(({ type, imgUrl, name, headerColour, cssOverrides }) => {
  const withAvatar = [
    'avatar_only',
    'avatar_and_text',
    'circle_avatar',
    'speech_bubble_avatar'
  ].includes(type);
  const withName = ['avatar_and_text', 'text_only'].includes(type);

  const children = (
    <>
      {withAvatar && <AvatarImage src={imgUrl} />}
      {withName && name}
    </>
  );

  switch (type) {
    case 'circle_avatar':
      return (
        <BubbleContainer
          headerColour={headerColour}
          top={17}
          cssOverrides={cssOverrides}
        >
          {children}
          <CircularBubble />
        </BubbleContainer>
      );
    case 'speech_bubble_avatar':
      return (
        <BubbleContainer
          headerColour={headerColour}
          top={13}
          cssOverrides={cssOverrides}
        >
          {children}
          <SpeechBubble />
        </BubbleContainer>
      );
    default:
      return (
        <CircularText
          headerColour={headerColour}
          withName={withName}
          withAvatar={withAvatar}
          cssOverrides={cssOverrides}
        >
          {children}
        </CircularText>
      );
  }
});

Widget.displayName = 'Widget';
Widget.propTypes = {
  type: PropTypes.oneOf([
    'avatar_only',
    'avatar_and_text',
    'text_only',
    'circle_avatar',
    'speech_bubble_avatar'
  ]),
  name: PropTypes.string,
  imgUrl: PropTypes.string,
  headerColour: PropTypes.string,
  cssOverrides: PropTypes.string
};

const CircularText = styled.div`
  ${({ withName, withAvatar, cssOverrides }) => css`
    background-color: ${({ headerColour }) =>
      headerColour || DEFAULT_PRIMARY_COLOR};
    box-shadow: 0px 3px 6px #06233d54;
    width: ${AVATAR_DIMS}px;
    height: ${withName ? 45 : AVATAR_DIMS}px;
    max-width: 255px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: ${AVATAR_DIMS / 2}px;
    gap: 6px;

    ${withName &&
    css`
      width: auto;
      font-size: 14px;
      padding: ${withAvatar ? '15px 12px' : '13px 18px'};
      img {
        height: 16px;
      }
    `}

    ${cssOverrides &&
    css`
      ${cssOverrides}
    `}
  `}
`;

const AvatarImage = styled.img`
  height: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
`;

const BubbleContainer = styled.div`
  ${({ headerColour, cssOverrides }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    color: ${headerColour};
    img {
      position: absolute;
      z-index: 1;
      max-height: 27px;
      max-width: 36px;
      top: ${({ top }) => top}px;
    }

    svg {
      filter: drop-shadow(0px 3px 3px #06233d54);
    }

    ${cssOverrides &&
    css`
      ${cssOverrides}
    `}
  `}
`;

export default Widget;
