import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import cssOverrides from '../utils/cssOverrides';

export default function QuickReply({ title, onClick, columnMode }) {
  const theme = useSelector(state => state.config.theme);
  const overrides = theme.cssOverrides?.QuickReply || {};
  const isEmoji = /^\p{Emoji}$/u.test(title);
  return !isEmoji ? (
    <Button
      theme={theme}
      css={overrides}
      onClick={onClick}
      columnMode={columnMode}
    >
      {title.replace(/\\/g, '')}
    </Button>
  ) : (
    <EmojiButton
      theme={theme}
      css={overrides}
      onClick={onClick}
      columnMode={columnMode}
    >
      {title}
    </EmojiButton>
  );
}

QuickReply.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  columnMode: PropTypes.bool
};

const Button = styled.button`
  ${({ columnMode }) => (columnMode ? 'width: 100%' : 'flex: 1;')};
  position: relative;
  background-color: transparent;
  border: thin dashed #d4d4d4;
  color: black;
  min-width: 100px;
  margin: 10px 10px 0 0;
  transition: all linear 0.1s;
  outline: none;
  cursor: pointer;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-align: center;
  word-break: break-word;
  font-size: 1em;

  &:hover {
    color: black;
    border-style: solid;
    border-color: #b3b3b3;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
  }

  &:focus {
    outline: none;
    border-style: solid;
    border-color: black;
  }

  &:active {
    background-color: #eeeeee;
    outline: none;
    border-color: #b3b3b3;
    border-style: solid;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
  }

  ${cssOverrides}
`;

const EmojiButton = styled(Button)`
  font-size: 1.2em;
  margin-top: 10px;
  height: 37px;
  border-radius: 50px;
  &&& {
    width: 36px;
    padding: 20px 19px 22px 19px;
  }
`;
