import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createSelfMessage, closeChatPanel } from '../../../reducer';
import { ExternalLink, InternalLink, SendIcon } from './Icons';
import useDimensions from '../../../../useDimensions';
import cssOverrides from '../../../utils/cssOverrides';
import Url from 'url-parse';
import { MOBILE_WIDTH } from '../../../utils/constants';

export default function Button({ url, title, type: baseType, payload }) {
  const theme = useSelector(state => state.config.theme);
  const dispatch = useDispatch();
  const { width } = useDimensions();

  const primaryColour = theme?.primaryColour || '#2574D4';
  const URL = useMemo(() => (url ? new Url(url) : {}), [url]);
  const hrefURL = useRef(new Url(window.location.href));
  const type =
    hrefURL.current.hostname === URL.hostname ? 'internal_url' : baseType;
  const props = { primaryColour, type };
  let icon = null;
  const cssOverride = theme?.cssOverrides?.WidgetButton;

  if (title === '' || url === '') {
    return null;
  }

  switch (type) {
    case 'postback':
      props.onClick = () => dispatch(createSelfMessage(payload, title));
      props.onKeyPress = e => {
        if (e.charCode === 13 || e.charCode === 32) {
          dispatch(createSelfMessage(payload, title));
        }
      };
      icon = (
        <SendIcon
          style={{
            color: 'black',
            width: '13px',
            height: '13px',
            marginRight: '0px',
            marginLeft: '5px'
          }}
        />
      );
      break;
    case 'web_url':
      icon = <ExternalLink />;
      props.target = '_blank';
      props.href = url;
      break;
    case 'internal_url':
      icon = <InternalLink />;
      props.onClick = () => {
        if (width <= MOBILE_WIDTH) {
          dispatch(closeChatPanel());
        }
      };
      props.href = url;
      break;
    default:
      props.target = '_blank';
      props.href = url;
  }

  return type === 'postback' ? (
    <HtmlButton css={cssOverride} {...props} tabIndex={0}>
      {title}
      {icon}
    </HtmlButton>
  ) : (
    <Link css={cssOverride} {...props} tabIndex={0}>
      {title}
      {icon}
    </Link>
  );
}

Button.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  payload: PropTypes.string
};

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: 1px solid;
  border-color: ${props => props.color || '#EEEEEE'};
  border-radius: 6px;
  text-decoration: none;
  padding: 10px;
  font-weight: 400;
  transition: background-color 75ms, color 75ms;
  color: black;
  word-break: break-word;
  background: ${props => props.color || '#EEEEEE'};
  font-style: normal;

  &:hover {
    background-color: ${props => props.color || '#EEEEEE'};
    color: black;
    border-color: #b3b3b3;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
  }

  &:focus {
    outline: none;
    border-color: black;
  }

  &:active {
    outline: none;
    border-color: #b3b3b3;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
    filter: brightness(90%);
  }

  & + & {
    margin-top: 10px;
  }

  & + button {
    margin-top: 10px;
  }

  ${cssOverrides}
`;

const HtmlButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border: 1px solid;
  border-color: ${props => props.color || '#EEEEEE'};
  border-radius: 6px;
  text-decoration: none;
  padding: 10px;
  font-weight: 400;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: black;
  word-break: break-word;
  background: ${props => props.color || '#EEEEEE'};
  font-style: italic;
  font-size: 16px;

  &:hover {
    background-color: ${props => props.color || '#EEEEEE'};
    color: black;
    border-color: #b3b3b3;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
  }

  &:focus {
    outline: none;
    border-color: black;
  }

  &:active {
    outline: none;
    border-color: #b3b3b3;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
    filter: brightness(90%);
  }

  & + & {
    margin-top: 10px;
  }

  & + a {
    margin-top: 10px;
  }

  ${cssOverrides}
`;
