import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { setCanAnimate } from '../../chat/reducer';
import { useTranslation } from '../../localisation/TranslationContext';
import emitter from '../../emitter';

const CLOSING_DURATION = 50;
const defaultStyles = {
  backgroundColor: '#2574D4'
};

export default function Banner({
  setIsOpen,
  headerColour,
  headerTextColour,
  cssOverrides,
  position
}) {
  const dispatch = useDispatch();
  const [isClosing, setIsClosing] = useState(false);
  const { t } = useTranslation();
  return (
    <Launcher
      position={position}
      isClosing={isClosing}
      style={{
        ...defaultStyles,
        ...{
          backgroundColor: headerColour,
          color: headerTextColour
        }
      }}
      cssOverrides={cssOverrides}
    >
      <Button
        onClick={() => {
          dispatch(setCanAnimate(true));
          setIsClosing(true);
          setTimeout(() => {
            dispatch(setCanAnimate(false));
          }, 350);
          setTimeout(() => {
            setIsClosing(false);
            setIsOpen(true);
            emitter.emit('window-opened');
          }, CLOSING_DURATION);
        }}
        headerTextColour={headerTextColour}
      >
        {t('banner')}
        {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="comment"
        class="svg-inline--fa fa-comment fa-w-16"
        role="img"
        viewBox="0 0 512 512"
      >
        <path
          fill="currentColor"
          d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32z"
        />
      </svg> */}
      </Button>
    </Launcher>
  );
}

Banner.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  headerColour: PropTypes.string,
  cssOverrides: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right']).isRequired,
  headerTextColour: PropTypes.string
};

const slideUp = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    transform: translateY(calc(100% + 20px));
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(calc(100% + 20px));
  }
`;

const Launcher = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  border-radius: 6px 6px 0 0;
  position: absolute;
  bottom: 0;
  height: 38px;
  width: 375px;
  z-index: 2147483646;
  ${({ position }) =>
    css`
      ${position}: 20px
    `};
  position: fixed;
  overflow: hidden;
  ${({ cssOverrides }) =>
    css`
      ${cssOverrides}
    `}
  animation: ${({ isClosing }) =>
    isClosing
      ? css`
          ${slideDown} ${CLOSING_DURATION}ms
        `
      : css`
          ${slideUp} 200ms
        `};
`;

const Button = styled.button`
  align-items: center;
  width: 100%;
  padding: 6px 12px;
  top: 0;
  height: 100%;
  z-index: 3;
  display: flex;
  text-align: unset;
  font: unset;
  border: none;
  color: ${({ headerTextColour }) =>
    headerTextColour ? headerTextColour : 'white'};
  box-shadow: rgba(0, 0, 0, 0.28) 0px 5px 15px;
  background: none;
  &:focus {
    outline: none;
    border: 2px solid rgba(0, 0, 0, 0.5);
    border-bottom: none;
    border-radius: 6px 6px 0 0;
    padding: 6px 10px;
  }
`;
