import PropTypes from 'prop-types';

const elementPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  buttons: PropTypes.arrayOf(PropTypes.object)
});

export default elementPropType;
