export const saveToSessionStorage = (dataKey, dataValue) => {
  sessionStorage.setItem(dataKey, dataValue);
};

export const fetchFromSessionStorage = dataKey => {
  const savedData = sessionStorage.getItem(dataKey);
  return savedData ? savedData : undefined;
};

export const removeFromSessionStorage = dataKey => {
  sessionStorage.removeItem(dataKey);
};
