import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { createSelfMessage } from '../../../reducer';

export default function ListButton({ url, title, type, payload }) {
  const theme = useSelector(state => state.config.theme);
  const dispatch = useDispatch();
  const primaryColour = theme?.primaryColour || '#2f2a2a';
  const props = { primaryColour };

  switch (type) {
    case 'postback':
      props.onClick = () => dispatch(createSelfMessage(payload, title));
      break;
    case 'web_url':
    default:
      props.target = '_blank';
      props.href = url;
  }

  return <Link {...props}>{title}</Link>;
}

ListButton.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  payload: PropTypes.string
};

const Link = styled.a`
  display: inline;
  cursor: pointer;
  text-align: center;
  border: 1px solid;
  border-color: ${({ primaryColour }) => primaryColour};
  border-radius: 4px;
  float: left;
  text-decoration: none;
  margin: 5px 5px 0 0;
  padding: 6px 10px;
  transition: background-color 75ms, color 75ms;
  color: ${({ primaryColour }) => primaryColour};

  &:hover {
    background-color: ${({ primaryColour }) => primaryColour};
    color: white;
  }

  &:first-of-type {
    margin-top: 8px;
  }
`;
