import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import emitter from '../../emitter';
import Widget from './Widget';
import Prompt from './Prompt';
import { isMobile } from 'react-device-detect';

const CLOSING_DURATION = 200;
const HIDDEN_LAUNCHER_TARGET_PATTERN = /^chat_content_/;
const DEFAULT_MARGIN = 62;
const AVATAR_DIMS = isMobile ? 54 : 70;
export default function FloatingActionButton({
  setIsOpen,
  isOpen,
  setIsPrompting,
  isPrompting,
  headerColour,
  headerTextColour,
  promptColour,
  promptTextColour,
  cssOverrides,
  launcherIconURL,
  position,
  prompt,
  promptCssOverrides,
  isEmbedded,
  embedTarget,
  animateLauncher,
  avatarName,
  widgetType = 'avatar_only',
  bottomMargin = DEFAULT_MARGIN,
  sideMargin = DEFAULT_MARGIN
}) {
  return (
    <Launcher
      position={position}
      isOpen={isOpen}
      isPrompting={isPrompting}
      prompt={prompt}
      onMouseOver={() => {
        !isOpen && prompt && !isPrompting && setIsPrompting(true);
      }}
      headerColour={headerColour}
      headerTextColour={headerTextColour}
      isEmbedded={isEmbedded}
      embedTarget={embedTarget}
      shouldAnimate={animateLauncher !== false && prompt?.hideAfterPrompt}
      bottomMargin={bottomMargin}
      sideMargin={sideMargin}
    >
      <Prompt
        headerColour={promptColour}
        position={position}
        isPrompting={isPrompting}
        cssOverrides={promptCssOverrides}
        headerTextColour={promptTextColour}
        prompt={prompt}
      />
      <Button
        colour={headerTextColour}
        onClick={() => {
          setTimeout(() => {
            setIsOpen(state => !state);
            emitter.emit('window-opened');
            prompt && setIsPrompting(false);
          }, CLOSING_DURATION);
        }}
      >
        <Widget
          headerColour={headerColour}
          type={widgetType}
          imgUrl={launcherIconURL}
          name={avatarName}
          cssOverrides={cssOverrides}
        />
      </Button>
    </Launcher>
  );
}

FloatingActionButton.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsPrompting: PropTypes.func.isRequired,
  isPrompting: PropTypes.bool.isRequired,
  prompt: PropTypes.shape({
    duration: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    hideAfterPrompt: PropTypes.bool.isRequired,
    style: PropTypes.string
  }),
  promptCssOverrides: PropTypes.string,
  headerColour: PropTypes.string,
  cssOverrides: PropTypes.string,
  launcherIconURL: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right', 'center']).isRequired,
  headerTextColour: PropTypes.string,
  isEmbedded: PropTypes.bool.isRequired,
  embedTarget: PropTypes.string,
  animateLauncher: PropTypes.bool,
  avatar: PropTypes.string,
  bottomMargin: PropTypes.number,
  sideMargin: PropTypes.number,
  widgetType: PropTypes.oneOf([
    'avatar_only',
    'avatar_and_text',
    'text_only',
    'circle_avatar',
    'speech_bubble_avatar'
  ]),
  avatarName: PropTypes.string.isRequired,
  promptColour: PropTypes.string,
  promptTextColour: PropTypes.string
};

const getTransform = ({
  prompt,
  isPrompting,
  isOpen,
  position,
  shouldAnimate,
  sideMargin
}) => {
  const translateDirection = position === 'center' ? '' : 'X';
  let translateValue = 0;
  const displacement = AVATAR_DIMS / 2;

  if ((prompt && !isPrompting && shouldAnimate) || isOpen) {
    if (position === 'center') {
      translateValue = `-50%, ${displacement}px`;
    } else {
      translateValue = `${
        position === 'left' ? -displacement : displacement
      }px`;
    }
  } else {
    if (position === 'center') {
      translateValue = '-50%, 0px';
    } else {
      translateValue = `${position === 'left' ? sideMargin : -sideMargin}`;
    }
  }

  return `translate${translateDirection}(${translateValue})`;
};
const Launcher = styled.div`
  bottom: ${({ bottomMargin }) => bottomMargin}px;
  z-index: 2147483646;
  position: fixed;
  color: ${({ headerTextColour }) => headerTextColour};
  ${({ position, sideMargin }) =>
    position === 'center'
      ? css`
          left: 50%;
        `
      : css`
          ${position}: ${sideMargin ?? 0}px;
        `}
  transform: ${({
    prompt,
    isPrompting,
    isOpen,
    position,
    shouldAnimate,
    sideMargin
  }) =>
    getTransform({
      prompt,
      isPrompting,
      isOpen,
      position,
      shouldAnimate,
      sideMargin
    })};
  transition: ${({ prompt, isPrompting, isOpen, shouldAnimate }) =>
    css`cubic-bezier(0.215, 0.61, 0.355, 1) transform ${
      (prompt && !isPrompting && shouldAnimate) || isOpen
        ? CLOSING_DURATION
        : 200
    }ms`};
  font-size: 0px;
  ${({ cssOverrides }) =>
    css`
      ${cssOverrides}
    `};
  ${({ isEmbedded, embedTarget }) =>
    isEmbedded &&
    HIDDEN_LAUNCHER_TARGET_PATTERN.test(embedTarget) &&
    css`
      opacity: 0;
    `};
`;

const Button = styled.button`
  top: 0;
  z-index: 3;
  border: none;
  position: relative;
  color: ${({ colour }) => colour};
  font-size: 14px;
  font-weight: 700;
  padding: 0px;
  background: none;
`;
