import React from 'react';
import ReactDOM from 'react-dom';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reduce as chatReducer } from './chat';
import { reduce as configReducer, getConfig } from './config';
import { loadState, persist } from './persist';
import { StyleSheetManager } from 'styled-components';
import App from './App';
import unicommsMiddleware from './chat/unicomms/middleware';
import { Provider } from 'react-redux';
import { TranslationProvider } from './localisation/TranslationContext';
import emitter from './emitter';
import {
  closeChatPanel,
  openChatPanel,
  agentDetailsUpdated,
  resetChat
} from './chat/reducer';
import { isMobile } from 'react-device-detect';

const constants = {
  apiURL: process.env.API_URL
};

const rootReducer = combineReducers({
  config: configReducer,
  chat: chatReducer
});

window.chatBot = {
  init: delayUntilLoad(init),
  open: delayUntilLoad(open),
  close: delayUntilLoad(close),
  updateAgent: delayUntilLoad(updateAgent),
  reset: delayUntilLoad(reset)
};

function returnEmitter() {
  return { on: (...args) => emitter.on(...args) };
}

function delayUntilLoad(func) {
  return function () {
    let initArgs = arguments;
    const execute = () => func.apply(this, initArgs);
    const { immediateLoad } = initArgs.length ? initArgs[0] : {};
    if (document.readyState === 'complete' || immediateLoad) {
      execute();
      return returnEmitter();
    }

    window.addEventListener('load', execute);

    return returnEmitter();
  };
}

export function getMiddleware({
  client,
  pipeline,
  debug,
  metadata,
  authentication,
  appName
}) {
  return unicommsMiddleware({
    api: constants.apiURL,
    client,
    pipeline,
    debug,
    metadata,
    authentication,
    appName
  });
}

export function close() {
  emitter.emit('close');
}

export function open() {
  emitter.emit('open');
}

export function updateAgent(agent) {
  emitter.emit('update-agent', agent);
}

export function reset() {
  emitter.emit('reset');
}

export async function init({
  client,
  pipeline,
  debug,
  embed = { enabled: false },
  metadata,
  fullScreen,
  startingMessage,
  authentication,
  startOpened,
  customAction
}) {
  const chatContent =
    (embed.enabled && embed.target && document.getElementById(embed.target)) ||
    document.getElementById('chat_content');

  if (!chatContent) {
    return;
  }

  const config = await getConfig({
    client,
    pipeline,
    apiURL: constants.apiURL
  });

  const shadowRoot = chatContent.attachShadow
    ? chatContent.attachShadow({ mode: 'closed' })
    : chatContent;

  const appName = config.appName;

  config.apiMiddleware = getMiddleware({
    client,
    pipeline,
    debug,
    metadata,
    authentication,
    appName
  });

  const primaryFont = config.theme.primaryFont
    ? config.theme.primaryFont
    : "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";

  config.fullScreen = embed.enabled ? false : fullScreen;
  config.startingMessage = startingMessage;
  config.client = client;
  config.pipeline = pipeline;
  config.embed = embed;
  config.customAction = customAction;

  try {
    const deviceSpecificConfig = config.theme[isMobile ? 'mobile' : 'desktop'];
    if (deviceSpecificConfig) {
      config.theme = {
        ...config.theme,
        ...deviceSpecificConfig
      };

      delete config.theme.mobile;
      delete config.theme.desktop;
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('No device specific config found...');
  }

  const startState = loadState({
    config
  });

  const store = createStore(
    rootReducer,
    startState,
    composeWithDevTools(applyMiddleware(config.apiMiddleware, persist))
  );

  emitter.on('close', () => store.dispatch(closeChatPanel()));
  emitter.on('open', () => store.dispatch(openChatPanel()));
  emitter.on('update-agent', agent =>
    store.dispatch(agentDetailsUpdated(agent))
  );
  emitter.on('reset', () => store.dispatch(resetChat()));

  if (startOpened) {
    store.dispatch(openChatPanel());
  }

  ReactDOM.render(
    <React.StrictMode>
      <style>
        {`
          :host {
            font-family: ${primaryFont};
          }

          * {
            box-sizing: border-box;
          }

          input:focus {
            outline: none;
          }
        `}
      </style>
      <StyleSheetManager target={shadowRoot}>
        <Provider store={store}>
          <TranslationProvider config={config}>
            <App />
          </TranslationProvider>
        </Provider>
      </StyleSheetManager>
    </React.StrictMode>,
    shadowRoot
  );
}
