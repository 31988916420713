import React, { createContext, useContext } from 'react';
import polyglot from './polyglotConfig';
import PropTypes from 'prop-types';

export const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

export const TranslationProvider = ({ children, config }) => {
  const locale = polyglot.locale();

  if (config.locale?.[locale]) {
    polyglot.extend({ ...config.locale[locale] });
  }

  const t = key => {
    return polyglot.t(key);
  };

  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};

TranslationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  config: PropTypes.shape({
    locale: PropTypes.object
  })
};
