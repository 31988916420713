import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { useSelector } from 'react-redux';

import cssOverrides from '../../utils/cssOverrides';
import { decodeSpecialChars } from '../../utils/decodeSpecialChars';
import Linkify from 'react-linkify';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { DEFAULT_SECONDARY_COLOR } from '../../utils/constants';
import { decode } from 'html-entities';

function LinkRenderer(props) {
  return (
    <a href={props.href} rel="noreferrer" target="_blank">
      {props.children}
    </a>
  );
}
export default function Bubble({
  isFirst,
  isLast,
  value,
  animate,
  displayMarkdown = false
}) {
  const botMessageColour = useSelector(
    state => state.config.theme.botMessageColour
  );
  const cssAll =
    useSelector(state => state.config.theme.cssOverrides?.OtherMessage) || {};
  const cssLast =
    useSelector(state => state.config.theme.cssOverrides?.LastOtherMessage) ||
    {};
  const cssFirst =
    useSelector(state => state.config.theme.cssOverrides?.FirstOtherMessage) ||
    {};
  const fullScreen = useSelector(state => state.config.fullScreen);

  const decodedValue = decode(value);

  return (
    <BubbleWrapper
      css={cssAll}
      cssFirst={isFirst && cssFirst}
      cssLast={isLast && cssLast}
      animate={animate}
      fullScreen={fullScreen}
      color={botMessageColour?.background || DEFAULT_SECONDARY_COLOR}
    >
      <BubbleText color={botMessageColour?.text || '#4b4f56'}>
        {displayMarkdown ? (
          <StyledMarkdown
            remarkPlugins={[remarkBreaks]}
            components={{ a: LinkRenderer }}
          >
            {decodedValue}
          </StyledMarkdown>
        ) : (
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {decodeSpecialChars(decodedValue)}
          </Linkify>
        )}
      </BubbleText>
    </BubbleWrapper>
  );
}

Bubble.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  animate: PropTypes.bool.isRequired,
  displayMarkdown: PropTypes.bool
};

LinkRenderer.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.string.func
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const BubbleWrapper = styled.div`
  background-color: ${props => props.color};
  margin: 7px 2px 7px 2px;
  max-width: 75%;
  ${({ fullScreen }) =>
    fullScreen
      ? css`
          @media screen and (min-width: 950px) {
            max-width: 65%;
          }
        `
      : null};
  width: fit-content;
  padding: 10px 14px;
  border-radius: 6px 10px 10px 6px;
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 0 0;
  transition: border-radius 300ms;
  a {
    color: inherit;
  }

  ${cssOverrides}

  ${props =>
    props.cssFirst &&
    css`
      border-top-left-radius: 10px;
      ${props.cssFirst}
    `}

  ${props =>
    props.cssLast &&
    css`
      border-bottom-left-radius: 10px;
      ${props.cssLast}
    `}
`;

const BubbleText = styled.p`
  font-size: 1em;
  line-height: 1.3em;
  color: ${props => props.color};
  margin: 0;
  word-break: break-word;
  white-space: pre-wrap;
`;

const StyledMarkdown = styled(Markdown)`
  line-height: 1.42;
  white-space: normal;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin: 0px 0px 16px 0px;

    &:last-child {
      margin: 0;
    }
  }

  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 16px;
    margin-top: 5px;
    padding-left: 16px;
    margin-left: 0px;
  }
  ol {
    padding-left: 1rem;
  }
  .ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
  }
  img {
    width: 100%;
  }
  pre {
    white-space: pre-wrap;
    margin-bottom: 16px;
    margin-top: 5px;
    padding: 10px 5px;
    border-radius: 3px;
    background-color: #fafafa80;
    font-size: 12px;

    &:last-child {
      margin: 0;
    }
  }

  p {
    code {
      background-color: #fafafa80;
      font-size: 12px;
      padding: 2px;
      border-radius: 4px;
    }
  }
`;
