import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

import GenericWidget from '../widgets/Generic';
import ListWidget from '../widgets/List';
import ButtonWidget from '../widgets/Button';
import Bubble from '../widgets/Bubble';
import FileUploadWidget from '../widgets/Generic/FileUploadWidget';

import QuickReplies from '../QuickReplies';
import QuickRepliesSelect from '../QuickRepliesSelect';

export default function OtherMessage({
  type,
  value,
  isFirst,
  isLast,
  replies = [],
  top_element_style,
  animate
}) {
  let widget;
  switch (type) {
    case 'generic':
      widget = <GenericMessage animate={animate} elements={value} />;
      break;
    case 'list':
      widget = (
        <ListWidget elements={value} top_element_style={top_element_style} />
      );
      break;
    case 'button':
      widget = <ButtonWidget {...value} />;
      break;
    case 'file-request':
      widget = <FileUploadWidget key={value} value={value} />;
      break;
    case 'text':
    default:
      widget = value && (
        <Bubble
          isFirst={isFirst}
          isLast={isLast}
          value={value}
          animate={animate}
          displayMarkdown
        />
      );
  }

  return (
    <WidgetWrapper>
      {widget}
      {replies.length > 0 && replies.length < 7 && (
        <QuickReplies animate={animate} replies={replies} />
      )}
      {replies.length >= 7 && (
        <QuickRepliesSelect animate={animate} replies={replies} />
      )}
    </WidgetWrapper>
  );
}

OtherMessage.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.arrayOf(PropTypes.any)
  ]),
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  replies: PropTypes.arrayOf(PropTypes.string.isRequired),
  top_element_style: PropTypes.string,
  animate: PropTypes.bool.isRequired
};

const zoomIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`;

const WidgetWrapper = styled.div`
  a:focus {
    outline-offset: -2px;
  }
`;

const GenericMessage = styled(GenericWidget)`
  transform: ${props => (props.animate ? 'scale(0)' : 'scale(1)')};
  ${props =>
    props.animate
      ? css`
          animation: ${zoomIn} 300ms;
        `
      : null}
  animation-fill-mode: forwards;
  transform-origin: 0 0;
`;
