import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from './widgets/Error';
import { TranslationContext } from '../../localisation/TranslationContext';

class ErrorBoundary extends Component {
  static contextType = TranslationContext;

  constructor(props) {
    super(props);
    this.state = {
      error: undefined
    };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const { t } = this.context;
    const { error } = this.state;

    if (error) {
      return <Error value={t('message_error')} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

export default ErrorBoundary;
